.confusion-matrix-container {
    max-height: 0;
    transition: 0.5s;
    overflow-x: scroll;
    width: 1040px;
}

.confusion-matrix-container.expand {
    max-height: 100%;
    background: white;
}




.confusion-matrix {
    border-collapse: collapse;
    
}

.confusion-matrix td {
    border: 1px solid #e4e4e4;
}



.confusion-matrix-container .intent-header td {
    height: 100px;
    white-space: nowrap;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    font-weight: normal;
    font-style: italic;
    color: #707070;
    position: relative;
    border: 0;
}

.confusion-matrix .intent-header td::before {
    content: " ";
    width: 1px;
    height: 6px;
    background-color: #E2E2E2;
    position: absolute;
    left: 50%;
    top: 0px;
}

.confusion-matrix .intent-header td:nth-child(1)::before,
.confusion-matrix .intent-header td:nth-child(2)::before {
    width: 0px;
}



.confusion-matrix-container .true-label {
    text-align: center;
    border: 0;
}

.confusion-matrix-container .true-label h4 {
    transform: /* Magic Numbers */
    translate(0px, 0px)
    /* 45 is really 360 - 45 */
    rotate(270deg);
    width: 60px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    font-family: 'Roboto';
    color: #707070;
}

.confusion-matrix-container .predicted-label h4 {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    font-family: 'Roboto';
    color: #707070;
}

.confusion-matrix-container .intent-header td> div {
    transform: /* Magic Numbers */
    translate(-16%, 30px)
    /* 45 is really 360 - 45 */
    rotate(290deg);
    width: 80px;
    text-align: right;
    /* width: 20px; */
}

.confusion-matrix-container .intent-cm-row {
    font-size: 10px;
}

.confusion-matrix-container .intent-cm-row:Hover {
    /* border: 1px solid var(--primary); */
    color: var(--primary);
}

.confusion-matrix-container .intent-cm-row .numbers {
    
    border: 1px solid #e2e2e2;
    width: 64px;
    height: 64px;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    font-style: italic;
    text-align: center;
    color: #707070;
}

.confusion-matrix-container .intent-label {
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    font-weight: normal;
    font-style: italic;
    color: #707070;
    border: 0;
    position: relative;
}



.confusion-matrix .intent-label::after {
    content: " ";
    width: 6px;
    height: 1px;
    background-color: #E2E2E2;
    position: absolute;
    right: -1px;
    top: 50%;
}



.confusion-matrix tr:last-child td {
    border: 0;
    position: relative;
}


.confusion-matrix tr:last-child td::after {
    width: 0px;
}



.confusion-matrix-container .confusion-matrix .graph-column {
    border: 0;
    padding-left: 58px;
}


.confusion-matrix-container .confusion-matrix .bar-grad {

    stroke: #E2E2E2;
    stroke-width: 1px;
}