.table-dataset-list-container {
    margin-bottom: 8px;
    max-height: 800px;
    overflow: scroll;
}
.table-dataset-list-container::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}


.table-dataset-list-container .table {
    background-color: #ffffff;
    border-collapse: separate;
    border-spacing: 0 5px;
}

.table-dataset-list-container .table thead > tr > th {
    color: rgba(53, 118, 167, 1);
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    font-size: 12px;
    border-bottom: 0px;
    padding: 2px 8px;
    line-height: 1rem;
}

.table-dataset-list-container .table .empty-td {
    border: 0px;
}


.table-dataset-list-container .table td {
    padding: 2px 8px;
}

.table-dataset-list-container .table td:first-child {
    border-left-width: 1px;
    border-radius: 5px 0 0 5px;
}
.table-dataset-list-container .table td:last-child {
    border-right-width: 1px;
    border-radius: 0 5px 5px 0;
}


.table-dataset-list-container .table tbody > tr > td {
    color: var(--textPrimary);
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
    font-family: 'Roboto', Arial;
    border-width: 1px 0;
    border-style: solid;
    border-color: rgba(230, 230, 230, 1);
    cursor: pointer;
}

.table-dataset-list-container .table .sort-label {
    color: rgba(53, 118, 167, 1);
}

.table-dataset-list-container .table .sort-icon {
    color: rgba(53, 118, 167, 1);
    opacity: 0.7;
}

.table-dataset-list-container .table .sort-icon-active {
    color: rgba(53, 118, 167, 1);
    opacity: 1;
    font-weight: bold;
}

.table-dataset-list-container .table .sort-icon-active > svg > path {
    color: rgba(53, 118, 167, 1);
}

.table-dataset-list-container .table .icon-button {
    padding: 4px;
    color: rgba(53, 118, 167, 1);

}


.table-dataset-list-container .table .icon {
    width: 16px;
    height: 16px;
}

.table-dataset-list-container .table .avatar-small {
    width: 20px;
    height: 20px;
    color: rgba(112, 112, 112, 1);
    font-size: 8px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    background-color: rgba(247, 247, 247, 1);
    border: 1px solid rgba(247, 247, 247, 1);

}

.table-dataset-list-container .table .rating-stars {
    color: rgba(53, 118, 167, 1);
}


/* CSS for Row hover */

.table-dataset-list-container .table tbody > .MuiTableRow-hover:hover {
    background-color: rgba(53, 118, 167, 1);
}
.table-dataset-list-container .table tbody > tr.MuiTableRow-hover:hover > td {
    color: white;
}
.table-dataset-list-container .table tbody > tr.MuiTableRow-hover:hover > td .rating-stars {
    color: white;
}
.table-dataset-list-container .table tbody > tr.MuiTableRow-hover:hover > td .icon-button {
    color: white;
}


/* CSS for Row selected */
.table-dataset-list-container .table tbody > .Mui-selected {
    background-color: rgba(53, 118, 167, 1);
}
.table-dataset-list-container .table tbody > .Mui-selected:hover {
    background-color: rgba(53, 118, 167, 1);
}
.table-dataset-list-container .table tbody > tr.Mui-selected> td {
    color: white;
}
.table-dataset-list-container .table tbody > tr.Mui-selected > td .rating-stars {
    color: white;
}
.table-dataset-list-container .table tbody > tr.Mui-selected > td .icon-button {
    color: white;
}