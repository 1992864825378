.create-competition-dialog {
    padding: 38px;
}

.create-competition-dialog h1 {
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-style: normal;
    font-size: 32px;
    line-height: 20px;
    font-size: calc(2vw + 0.3vh + .1vmin);
    line-height: calc(1vw + 0.1vh + .1vmin);
    letter-spacing: 0px;
    color: #3576A7;
}

.create-competition-dialog .competition-description {
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    font-size: calc(0.8vw + 0.1vh + .1vmin);
    line-height: calc(1vw + 0.1vh + .1vmin);
    font-family: 'Roboto';
    min-height: 79px;
}

.create-competition-dialog .competition-description:first-of-type {
    margin-right: 48px;
}

.create-competition-dialog .competition-image img {
    width: 514px;
    margin-top: 18px;
}


.create-competition-dialog .key-points {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
    width: 100%;
}

.create-competition-dialog .key-points:first-of-type {
    margin-top: 27px;
}

.create-competition-dialog .key-points img {
    max-width: 23px;
    width: 17px;
    width: 100%;
    margin-right: 12px;
    filter: brightness(1.2);

}

.create-competition-dialog .key-points .description {
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    font-size: calc(0.8vw + 0.1vh + .1vmin);
    line-height: calc(1vw + 0.1vh + .1vmin);
    font-family: 'Roboto';
}