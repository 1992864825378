.notifications-tooltip {
  padding: 12px;
  overflow: auto;
  max-height: calc(100vh - 300px);
  /* position: relative; */
  /* z-index: 5100; */
}

.notifications-tooltip .notification-button:hover {
  opacity: 0.7;
}
.notifications-tooltip ul li {
  color: rgb(112, 112, 112)
}

.notifications-tooltip .notifications-confirm {
  /* opacity: 0.7; */
  /* margin-top: 48px; */
  margin-top: 12px;
  /* position: absolute; */
  top: 0px;
  background-color: #fff;
  
  /* padding: 12px; */

}



