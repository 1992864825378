.onboard-wizard {
    text-align: justify;
    align-self: center;
    z-index: 1;
    padding: 0;
    margin: auto;
    margin-bottom: 48px;
  }
  
.onboard-wizard li {
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 44px;
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 400;
    border-radius: 24px;
    border: 2px solid #29B5C4;
    background-color: #FAFAFA;
    margin: 0 20px;
    display: inline-block;
    color: #29B5C4;
    position: relative;
  }
  
.onboard-wizard li::before{
    content: '';
    position: absolute;
    top: 24px;
    left: -36px;
    width: 30px;
    height: 0px;
    border: 1px dashed #29B5C4;
    z-index: -1;
  }

  /* hide the starting line */
.onboard-wizard li:first-child::before {
    display: none;
  }
  
.onboard-wizard .active {
    background: #FAFAFA;
}
  
.onboard-wizard .active ~ li {
    background: #FAFAFA;
    border: 2px solid #DBE9EC;
    color: #DBE9EC;
}
  
.onboard-wizard .active ~ li::before {
    border: 1px dashed #DBE9EC;
}

