.competitions-list {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 1335px;
}

.competitions-list .tab-Panel {
    padding: 8px 24px 16px;
}

.competitions-list .competition-head {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 10px;
    align-items: center;
    flex-wrap: nowrap;
}

.competitions-list .competition-head .avatar-group {
    display: flex;
    margin-right: 18px;
    align-items: center;
}

.competitions-list .competition-head .avatar-group .avatar {
    width: 28px;
    height: 28px;
    border: 1px solid #3576A7;
    padding: 1px;
    margin: 2px;
}

.competitions-list .competition-head .avatar-group .avatar img {
    border-radius: 50%;
}

.competitions-list .competition-head .description-text {
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Roboto';
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0px;
    color: #707070;
    max-width: 295px;
}

.competitions-list .competition-head .create-competition {
    border: 0px solid #4576A3;
    border-radius: 30px;
    /* width: 188px; */
    height: 40px;
    padding: 5px;
}

.competitions-list .competition-head .create-competition span {
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    color: #3576A7;
    text-transform: uppercase;
}