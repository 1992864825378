.cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.competition-card{
    display: flex;
    flex-direction: column;
}

.competition-card .tflops-container {
    background-color: #E1ECEF;
    border-radius: 2px;
    border: 1px solid #E1ECEF;
    height: 5px;
    min-width: 218px;
    max-width: 218px;
    position: relative;
    margin-left: 8px;
}

.competition-card .tflops-container .tflops-progress {
    background-color: #5DD39E;
    border-width: 1px;
    border-color: #5DD39E;
    border-style: solid;
    border-radius: 2px 0px 0px 2px;
    height: 5px;
    max-width: 100%;
    text-align: center;
    z-index: 1;
    position: absolute;
    top: -1px;
    left: -1px;
}

.competition-card .tflops-container .tflops-progress-text {
    text-align: center;
    position: absolute;
    display: flex;
    z-index: 2;
    width: 100%;
    height: 5px;
    color: #F7F7F7;
    font-size: 10px;
    line-height: 11px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}