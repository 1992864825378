.reset-pass-main {
    min-height: calc(100vh - 147px);
}

.reset-pass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}

.reset-pass .title {
    font-size: 48px;
    line-height: 60px;
    font-family: "Roboto";
    align-self: center;
    text-align: center;
    margin: 64px 0;
    font-weight: 700;
    color: #3576a7;
}

.reset-pass .sub-title {
    color: rgba(112, 112, 112, 1);
    line-height: 26px;
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    align-self: center;
    text-align: center;
    /* width: 92%; */
    margin: 14px auto;
}

.reset-pass .form {
    padding: 64px;
    max-width: var(--max-width);
    width: 651px;
    height: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 25px 25px #00000014;
    border-radius: 12px;
    opacity: 1;
    backdrop-filter: blur(21px);
    -webkit-backdrop-filter: blur(21px);

}


.reset-pass .form h3 {
    text-align: left;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0px;
    color: #3576A7;
    opacity: 1;
    width: 469px;
    padding: 0px;
    margin: 0px;
}


.reset-pass .form .error-message {
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0px;
    color: #E31010;
    opacity: 1;
    margin-bottom: 12px;
    margin-left: 14px;
    margin-right: 24px;
}


.reset-pass .form .reset-pass-password {
    margin-top: 0px;
    width: 469px;
    background: #EDF7FA 0% 0% no-repeat padding-box;
    border-radius: 2px;
    color: #212529;
    font-size: 18px;
    line-height: 26px;
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
}


.reset-pass .form .MuiOutlinedInput-input {
    font-size: inherit;
    padding: 9.5px 0px;
}



.reset-pass .form .reset-pass-password .MuiInputBase-root {
    line-height: 26px;
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    letter-spacing: 3.5px;
}

.reset-pass .form .reset-pass-password input::placeholder {
    font-size: 18px;
    letter-spacing: 0.0px;
}

.reset-pass .form .reset-pass-password fieldset {
    border-color: transparent;
}




.reset-pass .form .reset-pass-button {
    width: 469px;
    height: 60px;
    margin-top: 20px;
    background: #4BBDCC 0% 0% no-repeat padding-box;
    letter-spacing: 2px;
    color: #EDF7FA;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    font-style: normal;
    border-radius: 30px;
    letter-spacing: 2px;
}

.reset-pass a {
    color: rgba(53, 118, 167, 1);
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 0px 16px;
}

.reset-pass .form .error {
    color: red;
    font-size: 10px;
}


.reset-pass-main .terms-of-service {
    display: block;
    padding: 64px;
    max-width: var(--max-width);
    width: 709px;
    text-align: center;
    text-align: center;
    letter-spacing: 0px;
    color: #212529;
    font-size: 14px;
    line-height: 26px;
    font-weight: normal;
    font-style: normal;
}

.reset-pass-main .terms-of-service a {
    color: #3576A7;
    text-decoration: none;
    font-size: 14px;
    line-height: 26px;
    font-weight: normal;
    font-style: normal;
    padding: 0px;
}