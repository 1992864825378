.inference-container {
    margin: 16px 8px 26px 0px;
    padding-left: 8px;
    padding-right: 8px;
}

.inference-container .cycle-head {
    align-items: center;
    flex-wrap: nowrap;
}

.inference-container .cycle-head .line {
    background: #e2e2e2 0% 0% no-repeat padding-box;
    width: 100%;
    height: 1px;
}

.inference-container .cycle-head .selected {
    text-align: left;
    font-family: "Roboto";
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0px;
    color: #707070;
}

.inference-container .cycle-head .input-container {
    margin-left: 22px;
    min-width: 118px;
}

.inference-container .cycle-head .input-container span {
    text-align: left;
    font-family: "Roboto";
    font-weight: normal;
    font-style: normal;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0px;
    color: #707070;
}



.cycle-input-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 1px;
    margin-left: 3px;
}

.cycle-input-box .cycle-info {
    color: rgba(112, 112, 112, 1);
    font-size: 8px;
    font-weight: 300;
    font-style: italic;
    font-family: 'Roboto', Arial;
}

.cycle-input {
    padding: 2px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 24px;
    border: 1px solid #266B9A;
    border-radius: 2px;
  }

.cycle-input input {
        padding-left: 4px;
        padding-right: 4px;
        flex: 1;
        color: rgba(112, 112, 112, 1);
        font-size: 10px;
        line-height: 11px;
        font-weight: normal;
        font-style: normal;
        font-family: 'Roboto', Arial;
        min-width: 24px;
        max-width: 24px;
        border-radius: 2px 0px 0px 2px;
        text-align: center;

}

.cycle-input input[type=number]::-webkit-inner-spin-button, 
.cycle-input input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


.cycle-input .cycle-btn  {
    color: #ffffff;
    font-size: 10px;
    line-height: 11px;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
    border-radius: 0px 2px 2px 0px;
    font-family: 'Roboto', Arial;
    background-color: #3576a7;
    padding: 5px 16px;
    top: 0px;
    height: 24px;
    min-width: 51px;
}

.cycle-input .cycle-btn:hover {
    opacity: 0.9;
}

.cycle-input .cycle-btn.Mui-disabled {
    background-color: #C7D2D5;
    background-image: none;
    color: #ffffff;
}










.inference-cycle .cycle-btn  {
    color: #ffffff;
    font-size: 10px;
    line-height: 11px;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
    border-radius: 2px 2px 2px 2px;
    font-family: 'Roboto', Arial;
    background-color: #3576a7;
    padding: 5px 16px;
    top: 0px;
    height: 24px;
    min-width: 51px;
}

.inference-cycle .cycle-btn:hover {
    opacity: 0.9;
}

.inference-cycle .cycle-btn.Mui-disabled {
    background-color: #C7D2D5;
    background-image: none;
    color: #ffffff;
}








.experiment-view .inner-view .details-container .inference-cycle {
    /* padding: 0 8px; */
    margin: 0;
    padding-right: 0px;
    flex-wrap: nowrap;
    margin-top: 8px;
    justify-content: space-between;
}



.experiment-view .inner-view .details-container .inference-cycle .item {
    display: flex;
    flex-direction: row;
    /* padding-left: 20px;
    padding-right: 20px; */
    flex-wrap: nowrap;
    margin-bottom: 4px;
    /* max-width: 130px;
    flex-basis: 20%; */
}

.experiment-view .inner-view .details-container .inference-cycle .item .item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;

}


.experiment-view .inner-view .details-container .inference-cycle .item .head {
    /* width: 60%;
    text-align: left; */
    letter-spacing: 0.00px;
    color: rgba(112, 112, 112, 1);
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;

}



.experiment-view .inner-view .details-container .inference-cycle .item .inference-text {
    /* width: 40%;
    text-align: right; */
    letter-spacing: 0px;
    color: #3576A7;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    margin-left: 18px;
}