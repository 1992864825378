.global-header {
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.search-box {
  margin: auto;
  max-width: 408px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  border-color: #e6e6e6;
  border-radius: 5px;
  /* width: 400px; */
}

.input-box {
    margin-left: 5px;
    flex: 1;
    color: #3576a7;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Roboto', 'Arial';
    font-style: italic;
  }
.iconButton {
    margin-left: 5px;
    color: #3576a7;
    width: 19px;
    height: 19px;
  }

.divider {
    height: 28px;
    margin: 4px;
}

.global-header .colab-button {
  min-width: 84px;
  height: 32px;
  padding: 10px 17px;
  margin-left: 24px;
  border: 1px solid #266B9A;
  border-radius: 16px;
}

.global-header .colab-button .MuiButton-label {

  font-size: 10px;
  line-height: 11px;
  font-weight: normal;
  font-family: 'Roboto', 'Arial';
  font-style: italic;
  padding: 6px 2px;
  text-align: left;
  letter-spacing: 0px;
  color: #3576A7;
  text-transform: none;
}

.global-header .colab-button span svg {
  margin-left: 4px;
  color: #3576A7;
  font-size: 20px;
  font-weight: normal;
  font-family: 'Roboto', 'Arial';
  font-style: normal;
}



.global-header .notifications-button {
  /* min-width: 14px;
  height: 18px; */
  margin-left: 16px;
}


.global-header .notifications-button span svg {
  color: #E2E2E2;
  font-size: 23px;
  font-weight: normal;
  font-family: 'Roboto', 'Arial';
  font-style: normal;
}


.global-header .tflops-text {
  text-align: left;
  font-family: 'Roboto';
  font-size: 10px;
  line-height: 13px;
  font-weight: normal;
  font-style: italic;
  letter-spacing: 0px;
  color: #909B9D;
  cursor: pointer;
  padding: 10px 0px;
}

.global-header .tflops-text span {
  color: #C7D2D5;
  margin-right: 12px;
}



.global-header .nav a {
  text-decoration: none;
  font-weight: 400;
  color: #3576a7;
  margin-left: 9px;
  display: flex;
  align-items: center;
}

.global-header .nav a:hover {
  font-weight: 500;
}

.global-header .nav a.selected {
  color: #3576a7;
}






.flops-tooltip {
  padding: 12px;
}

.flops-tooltip .tooltip-flops-title {
  text-align: center;
  letter-spacing: 0px;
  font-size: 11px;
  line-height: 14px;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
}

.flops-tooltip .tooltip-flops-value {
  margin-top: 8px;
  text-align: center;
  letter-spacing: 0px;
  font-size: 11px;
  line-height: 14px;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: bold;
  color: #3576A7;
}