.register-user-main {
    min-height: calc(100vh - 80px);
    

}

.register-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}

.register-user .title {
    font-size: 64px;
    line-height: 60px;
    font-family: "Roboto";
    align-self: center;
    text-align: center;
    margin: 48px 0;
    font-weight: 700;
    color: #3576a7;
    letter-spacing: -1.6px;

}

.register-user .sub-title {
    color: rgba(112, 112, 112, 1);
    line-height: 26px;
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 400;
    font-style: italic;
    align-self: center;
    text-align: center;
    width: 62%;
    margin: 64px auto;
}

.register-user .form {
    margin: 30px 50px 20px;
    padding: 20px 40px;
    max-width: var(--max-width);
    width: 600px;
    min-height: 390px;
    height: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 25px 25px #00000014;
    border-radius: 12px;
    opacity: 1;
    backdrop-filter: blur(21px);
    -webkit-backdrop-filter: blur(21px);
}

.register-user .form h3 {
    text-align: left;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0px;
    color: #3576A7;
    opacity: 1;
    width: 469px;
    padding: 0px;
    margin: 0px;
}


.register-user .form .login-username {
    margin-top: 0px;
    width: 469px;
    background: #EDF7FA 0% 0% no-repeat padding-box;
    border-radius: 2px;
    color: #707070;
    font-size: 18px;
    line-height: 26px;
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
    margin-bottom: 12px;
}

.register-user .form .login-username .MuiInputBase-root {
    font-size: 18px;
    line-height: 26px;
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
}
.register-user .form .MuiOutlinedInput-input {
    font-size: inherit;
    padding: 9.5px 0px;
}


.register-user .form .login-password {
    margin-bottom: 8px;
    margin-top: 0px;
    width: 469px;
    background: #EDF7FA 0% 0% no-repeat padding-box;
    border-radius: 2px;
    color: #707070;
    font-size: 18px;
    line-height: 26px;
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
}

.register-user .form .login-password .MuiInputBase-root {
    font-size: inherit;
}

.register-user .form .login-username fieldset,
.register-user .form .login-password fieldset {
    border-color: transparent;
}

.register-user a {
    color: rgba(53, 118, 167, 1);
    font-size: 16px;
    line-height: 26px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    margin-top: 20px;

}

.register-user .form .login-button {
    width: 469px;
    height: 60px;
    margin-top: 12px;
    background: #4BBDCC 0% 0% no-repeat padding-box;
    letter-spacing: 2px;
    color: #EDF7FA;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    font-style: normal;
    border-radius: 30px;
    letter-spacing: 2px;

}

.register-user .form .MuiFormHelperText-root {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-style: italic;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #E31010;
    margin: 0px;
    padding: 8px 8px;
}
.register-user .form .error {
    color: red;
    background-color: #FFFFFF;
}


.register-user-main .terms-of-service {
    display: block;
    padding: 64px;
    max-width: var(--max-width);
    width: 709px;
    text-align: center;
    text-align: center;
    letter-spacing: 0px;
    color: #212529;
    font-size: 14px;
    line-height: 26px;
    font-weight: normal;
    font-style: normal;
}

.register-user-main .terms-of-service a {
    color: #3576A7;
    text-decoration: none;
    font-size: 14px;
}




@media screen and (max-width: 480px) {
    .register-user-main .form {
        padding: 30px 30px;
        width: 90%;
        min-height: 406px;
        height: 100%;
        max-height: 100%;
    }

    .register-user-main .form .login-username,
    .register-user-main .form .login-password,
    .register-user-main .form .login-button,
    .register-user-main .form h3 {
        width: 90%;
        min-width: 330px;
    }

    .register-user-main .terms-of-service {
        width: 100%;
        padding: 30px;
    }

    


  }