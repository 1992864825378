.team-popup {

    max-width: 225px;

}

.team-popup .team-header {
    text-align: left;
    letter-spacing: 0.00px;
    color: rgba(112, 112, 112, 1);
    -webkit-text-stroke: unset;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    text-decoration: none;
}

.team-popup .teams-list {
    max-height: 300px;
    overflow: auto;
}
.team-popup .teams-list .team-row {
    padding: 8px 0px;
    color: rgba(53, 118, 167, 1);
    -webkit-text-stroke: unset;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    text-decoration: none;
    text-align: left;
    align-items: center;
}

.team-popup .teams-list > div:not(:last-child) {
    /* border-bottom: 1px solid #e6e6e6; */
}

.team-popup .teams-list .team-row .avatar-small {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}



.team-popup .teams-list .team-row .team-name {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    font-weight: normal;
    font-style: italic;
    font-size: 10px;
    line-height: 11px;
    font-family: 'Roboto';
}
.team-popup .teams-list .team-row .team-member {
    color: var(--textPrimary);
    font-weight: normal;
    font-style: italic;
    font-size: 10px;
    line-height: 11px;
    font-family: 'Roboto';
    cursor: pointer;
    text-align: left;
    margin-left: 8px;
}

.team-popup .host-container {
    border-top: 1px solid #e6e6e6;
}

.team-popup .host-container .hosted-by {
    border-radius: 4px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    z-index: 1;
    font-size: 10px;
    color: var(--textPrimary);
    
}

.team-popup .host-container .host-text {
    width: 100%;
    color: var(--primary);
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    flex-wrap: nowrap;
}



.team-popup .auto-complete-user .avatar-small {
    width: 10px;
    height: 10px;
}