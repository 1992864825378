
.register-user .resend-verification {
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;

}

.register-user .resend-verification span {
    color: rgba(53, 118, 167, 1);
    text-decoration: none;
    margin-top: 20px;
    cursor: pointer;

}


.register-user .error-message {
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0px;
    color: #E31010;
    opacity: 1;
    margin-bottom: 12px;
}
