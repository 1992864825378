
.profile-update-main {
    min-height: calc(100vh - 415px);
    /* margin-top: 120px; */
    background-color: #ffffff;
}

.profile-update {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 415px );
    background: #FAFAFA 0% 0% no-repeat padding-box;

}

.profile-update .title {
    font-size: 64px;
    line-height: 60px;
    font-family: "Roboto";
    align-self: center;
    text-align: center;
    margin: 48px 0;
    margin-top: 0px;
    font-weight: 700;
    color: #3576a7;
    letter-spacing: -1.6px;
}

.profile-update .sub-title {
    color: rgba(112, 112, 112, 1);
    line-height: 26px;
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    align-self: center;
    text-align: center;
    width: 68%;
    margin: 24px auto;
}

.profile-update .form {
    max-width: var(--max-width);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 25px 25px #00000014;
    border-radius: 12px;
    width: 709px;
    height: 593px;
    justify-content: center;
    align-items: center;

}
.profile-update .thank-you {
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    font-family: 'Roboto';
    font-weight: 500;
    letter-spacing: 0px;
    color: #4BBDCC;
    opacity: 1;
    padding-left: 120px;
    padding-right: 120px;
}

.profile-update .form .profile-picture {
    align-self: center;
    text-align: center;
    margin-bottom: 20px;
    /* width: 56px;
    height: 79px; */

}

.profile-update .form .error {
    color: red;
    font-size: 10px;
}

.profile-update .form .MuiFormHelperText-contained {
    background-color: #FFFFFF;
    margin: 0;
    padding-left: 14px;
    padding-right: 14px;
}

.profile-update .form .profile-update-name {
    margin-top: 0px;
    margin-bottom: 20px;
    width: 469px;
    background: #EDF7FA 0% 0% no-repeat padding-box;
    border-radius: 2px;
    color: #707070;

}

.profile-update .form .profile-update-name fieldset,
.profile-update .form .profile-update-name fieldset {
    border-color: #FFFFFF;
}

.profile-update .profile-update-button {
    width: 469px;
    height: 59px;
    margin-top: 40px;
    background: #4BBDCC 0% 0% no-repeat padding-box;
    letter-spacing: 2px;
    color: #EDF7FA;
    text-transform: uppercase;
    font: normal normal bold 20px/24px 'Roboto';
    letter-spacing: 2px;
    border-radius: 5px;
    
}
