.footer {
    height: 35px;
    width: 100%;
    position: relative;
    padding: 0px 200px;
    padding-bottom: 64px;
}

.footer .nav {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.footer .nav > .links {
    max-width: var(--max-width);
    height: 100%;
}

.footer .nav a {
    display: flex;
    align-items: center;
    line-height: 32.00px;
    letter-spacing: 0.00px;
    color: rgba(53, 118, 167, 1);
    font-size: 12.00px;
    font-weight: 400;
    font-style: normal;
    text-transform: capitalize;
    text-decoration: none;
}

.footer .nav a:hover {
    opacity: 0.7;
}

.footer .nav button span,
.footer .nav div {
    text-align: left;
    line-height: 32.00px;
    letter-spacing: 0.00px;
    color: rgba(53, 118, 167, 1);
    font-size: 12.00px;
    font-weight: 400;
    font-style: normal;
    text-transform: capitalize;
    z-index: 1  ;
}

.footer .nav img {
    height: 35px;
}

.footer .circle-one {
    fill: #FFFFFF;
    position: absolute;
    right: 0px;
    bottom: 150px;
    width: 199px;
    height: 199px;
}

.footer .circle-two {
    bottom: 0px;
    right: 0px;
    width: 314px;
    height: 314px;
    fill: #FFFFFF;
    opacity: 1;
    position: absolute;
}

