.ck-editor__editable {
  min-height: 200px;
  resize: vertical;
  overflow-y: auto !important;
}

/* Card Styles */
.admin-card {
  background: #FFFFFF;
  border-radius: 12px !important;
  box-shadow: none !important;
  border: 1px solid #E5E5E5 !important;
  padding: 24px !important;
  height: 100%;
}

/* Typography Styles */
.card-title {
  color: #2196F3;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px !important;
}

.section-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 24px !important;
}

/* Input Styles */
.budget-input {
  background: #FFFFFF;
}

.budget-input .MuiOutlinedInput-root {
  border-radius: 4px;
  height: 40px;
}

.budget-input .MuiOutlinedInput-input {
  padding: 10px 14px;
  font-size: 14px;
}

.budget-input .MuiOutlinedInput-notchedOutline {
  border-color: #E0E0E0;
}

/* Button Styles */
.apply-button {
  background-color: #3576A7 !important;
  color: white !important;
  text-transform: none !important;
  border-radius: 12px !important;
  padding: 6px 16px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 40px !important;
  min-width: 100px !important;
  max-width: fit-content;
  margin-left: auto;
}

.upload-button {
  background-color: transparent !important;
  color: #3576A7 !important;
  border: 1px solid #3576A7 !important;
  text-transform: none !important;
  border-radius: 12px !important;
  padding: 6px 16px !important;
  min-width: 140px !important;
  margin-right: 10px;
}

/* Switch Styles */
.experiment-switch {
  color: #3576A7 !important;
}

.experiment-switch .MuiSwitch-track {
  background-color: #E0E0E0 !important;
  opacity: 1 !important;
}


.experiment-switch .Mui-checked + .MuiSwitch-track {
  background-color: rgba(33, 150, 243, 0.5) !important;
}

.experiment-switch  .MuiSwitch-thumb {
  background-color: #666 !important;
}

.experiment-switch .Mui-checked .MuiSwitch-thumb {
  background-color: #3576A7 !important;
}

.experiment-switch .Mui-disabled .MuiSwitch-thumb {
  background-color: #999 !important;
}

/* Table Styles */
.users-table-container {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 20px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
}

.users-table {
  margin-top: 0 !important;
}

.users-table .MuiTableHead-root {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #FAFAFA;
}

.users-table .MuiTableCell-head {
  background-color: #FAFAFA;
  color: #666;
  font-weight: 500;
  border-bottom: 1px solid #E0E0E0;
}

/* Scrollbar Styling */
.users-table-container::-webkit-scrollbar {
  width: 8px;
}

.users-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.users-table-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.users-table-container::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Helper Text */
.helper-text {
  color: #666;
  font-size: 14px;
  margin-bottom: 8px !important;
}

/* Budget Display */
.budget-display {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  margin: 16px 0 !important;
}

.budget-label {
  color: #666;
  font-size: 14px;
  margin-bottom: 8px !important;
}

/* Card Header */
.card-header {
  color: #3576A7;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 24px !important;
}

/* Input with Button */
.input-with-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.input-with-button .MuiOutlinedInput-root {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-with-button .apply-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Configuration Cards Layout */
.config-card {
  flex: 1;
  min-width: 0;
  width: calc(33.333% - 32px);
  min-height: 250px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  transition: border-color 0.2s ease;
  position: relative;
}

.config-card:hover {
  border-color: #D0D0D0 !important;
}

/* Adjust the gap between cards */
.config-card + .config-card {
  margin-left: 24px;
}

/* Switch Status Text */
.switch-status-on {
  color: #4CAF50;
  font-weight: 500;
}

.switch-status-off {
  color: #f44336;
  font-weight: 500;
}

.flops-label p {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}

.date-card {
  flex: 1;
  min-width: 0;
  width: calc(33.333% - 32px);
  min-height: 50px;
  padding: 16px;
  margin: 16px;

  margin: 8px !important;
  border: 1px solid #e0e0e0 !important;
  transition: all 0.3s ease;
}

.date-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08) !important;
  transform: translateY(-2px);
}

.date-label {
  color: #3576A7;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 4px;
}

.date-description {
  color: #5f6368;
  font-size: 0.75rem;
  line-height: 1.4;
  margin-top: 8px;
}

.date-input {
  background-color: white;
  border-radius: 4px;
}

.date-input:focus {
  border-color: #3576A7;
}

/* Privacy Select Styles */
.privacy-select .MuiOutlinedInput-root {
  background-color: white;
  border-radius: 4px;
  height: 40px !important;
  width: 100% !important;
}

.privacy-select .MuiSelect-outlined {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.privacy-select .MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1);
}

.privacy-select .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}

.privacy-select .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
}

.privacy-select .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #3576A7;
}

.privacy-select .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3576A7;
}

.privacy-select .MuiFormLabel-root.Mui-focused {
  color: #3576A7;
}

.privacy-description {
  color: #666;
  font-size: 0.875rem;
  line-height: 1.4;
}
