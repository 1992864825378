.custom-tab-container .MuiTabs-flexContainer button:first-child {
    border-top-left-radius: 5px;
    margin-left: 1px;
}

.custom-tab-container .MuiTabs-flexContainer button:last-child {
    border-top-right-radius: 5px;
}


.defect-bar-legend {
    display: flex;
    flex-direction: column;
    width: 92px;
    align-items: flex-start;
    justify-content: flex-end;
}

.defect-bar-legend .crack,
.defect-bar-legend .inclusion,
.defect-bar-legend .porosity {
    text-align: center;
    margin: 0 8px;
    color: rgba(112, 112, 112, 1);
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
 }

 .defect-bar-legend .crack::before, 
 .defect-bar-legend .inclusion::before,
 .defect-bar-legend .porosity::before {
    display: inline-block;
    content: "";
    width: 16px;
    transform: translateY(-2px);
    margin-right: 10px;
}

.defect-bar-legend .crack::before {
    border-top: 1px solid rgba(65, 14, 82, 1);
}

.defect-bar-legend .inclusion::before {
    border-top: 1px solid rgba(255, 156, 41, 1);
}

.defect-bar-legend .porosity::before {
    border-top: 1px solid rgba(125, 208, 162, 1);
}


.defect-bar-tooltip {
    display: flex;
    flex-direction: column;
    padding: 8px;
    font-size: 10px;
    text-align: left;

}

.defect-bar-tooltip span {
    color: rgba(112, 112, 112, 1);
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    margin-right: 10px;
    text-align: left;

 }


.defect-bar-tooltip .crack {
    color: rgba(65, 14, 82, 1);
}

.defect-bar-tooltip .inclusion {
    color: rgba(255, 156, 41, 1);
}

.defect-bar-tooltip .porosity {
    color: rgba(125, 208, 162, 1);
}

.text-10 {
    font-size: 10px;
}