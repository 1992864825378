.carousel-container {
    background-color: #ffffff;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}
  
.carousel-container .card {
    background: #ffffff;
    width: 100%;
    height: 100%;
}

.carousel-container .card:first-child {
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(25%, 1fr));
}
