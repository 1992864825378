.dataset-description {
    height: 100%;
    padding: 12px;
    text-align: left;
    font-family: "Roboto";
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    font-style: italic;
    color: #333;

}


.dataset-description a {
    text-decoration: none;
    color: #3576a7;
    
}

.dataset-description ul {
    margin-left: 13px;
    
}

h4 {
    margin-top: 40px;
    text-align: left;
    letter-spacing: 0px;
    color: #3576A7;
    font-family: "Roboto";
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    font-style: italic;
}
