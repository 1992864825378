.table-conatainer {
    margin-bottom: 8px;
    margin-top: 16px;
}
.table-conatainer .competition-table {
    background-color: #ffffff;
    border-collapse: separate;
    border-spacing: 0 12px;
    border: 0;
    width: 100%;
}

.table-conatainer .competition-table .tflops-container {
    background-color: #C7D2D5;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    height: 24px;
    position: relative;
}

.table-conatainer .competition-table .tflops-container .tflops-progress {
    background-color: #3576A7;
    border: 1px solid #3576A7;
    border-radius: 4px 0px 0px 4px;
    height: 22px;
    max-width: 100%;
    text-align: center;
    z-index: 1;
    position: absolute;
}

.table-conatainer .competition-table .tflops-container .tflops-progress-text {
    text-align: center;
    position: absolute;
    display: flex;
    z-index: 2;
    width: 100%;
    height: 24px;
    color: #F7F7F7;
    font-size: 10px;
    line-height: 11px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.table-conatainer .competition-table thead > tr > th {
    color: rgba(53, 118, 167, 1);
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    font-size: 12px;
    border-bottom: 0px;
    padding: 2px 8px;
    line-height: 1rem;
    text-align: center;
}


.table-conatainer .competition-table thead > tr > th:last-child {
    padding-right: 40px;
    text-align: right;
}

.table-conatainer .competition-table thead > tr > th:nth-child(2) {
    padding-left: 26px;
    text-align: left;
}

/* The 'Remaining Days' table Header */
.table-conatainer .competition-table thead > tr > th:nth-child(5) {
    text-align: right;
}


.table-conatainer .competition-table .empty-td {
    border: 0px;
}


.table-conatainer .competition-table td {
    padding: 2px 8px;
}

.table-conatainer .competition-table td:first-child {
    border-left-width: 1px;
    border-right-width: 1px;
    border-radius: 5px 0 0 5px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    padding-right: 8px;
}
.table-conatainer .competition-table td:last-child {
    border-right-width: 1px;
    border-radius: 0 5px 5px 0;
    padding-right: 12px;
}


.table-conatainer .competition-table tbody > tr > td {
    color: rgba(53, 118, 167, 1);
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
    font-family: 'Roboto', Arial;
    border-width: 1px 0;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: #e6e6e6;
    cursor: pointer;
    padding-right: 28px;
}

.table-conatainer .competition-table tbody > tr > td li.MuiListItem-container {
    display: block;
}

.table-conatainer .competition-table .sort-label {
    color: rgba(53, 118, 167, 1);
}

.table-conatainer .competition-table .sort-icon {
    color: rgba(53, 118, 167, 1);
    opacity: 0.7;
}

.table-conatainer .competition-table .sort-icon-active {
    color: rgba(53, 118, 167, 1);
    opacity: 1;
    font-weight: bold;
}

.table-conatainer .competition-table .sort-icon-active > svg > path {
    color: rgba(53, 118, 167, 1);
}

.table-conatainer .competition-table .icon-button {
    padding: 4px;
    color: rgba(53, 118, 167, 1);

}


.table-conatainer .competition-table .icon {
    width: 20px;
    height: 20px;
}

.table-conatainer .competition-table .avatar-small {
    width: 20px;
    height: 20px;
    color: rgba(112, 112, 112, 1);
    font-size: 8px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    background-color: rgba(247, 247, 247, 1);
    border: 1px solid rgba(247, 247, 247, 1);

}

.table-conatainer .competition-table .rating-stars {
    color: rgba(53, 118, 167, 1);
}


/* CSS for Row hover */

.table-conatainer .competition-table tbody > .MuiTableRow-hover:hover {
    background-color: rgba(53, 118, 167, 1);
}
.table-conatainer .competition-table tbody > tr.MuiTableRow-hover:hover > td {
    color: white;
}
.table-conatainer .competition-table tbody > tr.MuiTableRow-hover:hover > td:first-child {
    background-color: white;
}
.table-conatainer .competition-table tbody > tr.MuiTableRow-hover:hover > td .rating-stars {
    color: white;
}
.table-conatainer .competition-table tbody > tr.MuiTableRow-hover:hover > td .icon-button {
    color: white;
}
.table-conatainer .competition-table tbody > tr.MuiTableRow-hover:hover > td .MuiTypography-colorTextPrimary {
    color: white;
}
.table-conatainer .competition-table tbody > tr.MuiTableRow-hover:hover > td .MuiTypography-colorTextSecondary {
    color: rgba(255, 255, 255, 0.54);
}
.table-conatainer .competition-table tbody > tr.MuiTableRow-hover:hover > td .MuiTypography-subtitle2 {
    color: #f7f7f7;
}
.table-conatainer .competition-table tbody > tr.MuiTableRow-hover:hover > td .MuiTypography-caption {
    color: #f7f7f7;
}

.table-conatainer .competition-table tbody > tr.MuiTableRow-hover:hover > td .tflops-container .tflops-progress {
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
}


.table-conatainer .competition-table tbody > tr.MuiTableRow-hover:hover > td .tflops-container .tflops-progress-text {
    color: #3576A7
}

/* CSS for Row selected */
.table-conatainer .competition-table tbody > .Mui-selected {
    background-color: rgba(53, 118, 167, 1);
}
.table-conatainer .competition-table tbody > .Mui-selected:hover {
    background-color: rgba(53, 118, 167, 1);
}
.table-conatainer .competition-table tbody > tr.Mui-selected> td {
    color: white;
}
.table-conatainer .competition-table tbody > tr.Mui-selected > td .rating-stars {
    color: white;
}
.table-conatainer .competition-table tbody > tr.Mui-selected > td .icon-button {
    color: white;
}
.table-conatainer .competition-table tbody > tr.Mui-selected > td .MuiTypography-colorTextPrimary {
    color: white;
}
.table-conatainer .competition-table tbody > tr.Mui-selected > td .MuiTypography-colorTextSecondary {
    color: rgba(255, 255, 255, 0.54);
}

.table-conatainer .competition-table tbody > tr.Mui-selected > td .tflops-container .tflops-progress {
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
}


.table-conatainer .competition-table tbody > tr.Mui-selected > td .tflops-container .tflops-progress-text {
    color: #3576A7
}




.table-conatainer .competition-table .create-competition {
    border: 1px solid #4576A3;
    border-radius: 30px;
    min-width: 100px;
    max-width: 188px;
    height: 40px;
    background-color: #FeFeFe;
}

.table-conatainer .competition-table .create-competition:hover span {
    font-weight: 500;
}



.table-conatainer .competition-table .create-competition span {
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    color: #3576A7;
    text-transform: uppercase;
}

.table-conatainer .competition-table .create-competition .icon {
    color: greenyellow;
}