
.tab-Panel {
    padding: 8px 24px 8px;
}

.competition-header__container {
    margin-bottom: 24px;
}

.pageheader {
    border-radius: 4px 4px 0 0;
    margin: 0;
    max-width: 100%;
    width: 100%;
}

.pageheader__container {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    z-index: 2;
    position: relative;
    top: 1px;
}

.competition-header__top {
    font-family: Inter,sans-serif;
    border: 1px solid #dedfe0;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding-bottom: 12px;
}

.competition-header__top .competition-header__top-image {
    height: 130px;
    width: 100%;
    background-size: cover;
}

.competition-header__top .competition-status {
    max-width: 210px;
    min-height: 24px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    font-family: 'Roboto';
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 4px 8px;
    position: absolute;
    right: 32px;
    top: 32px;
    border-radius: 2px;
    border: 1px solid #FFFFFF;
}
.competition-header__top .competition-status.progress {
    background: #FFA700 0% 0% no-repeat padding-box;

}


.pageheader__top--safe {
    border-radius: 4px 4px 0 0;
    position: relative;
    width: 100%;
}


.competition-header__meta {
    display: flex;
    flex-wrap: nowrap;
    justify-content:space-between;
    align-items: center;
    padding: 0 24px;
    margin-top: 21px;
}




.competition-header__meta  .avatars-group {
    justify-content: flex-start;
}

.competition-header__meta  .avatars-group .avatar-small {
    width: 24px;
    height: 24px;
    color: #707070;
    font-size: 8px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto",Arial;
    background-color: #f7f7f7;
    border: 1px solid #E2E2E2;
    
}

/* .competition-header__meta  .avatars-group .avatar-small:first-child {
    width: 24px;
    height: 24px;
    color: #707070;
    font-size: 8px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto",Arial;
    background-color: #f7f7f7;
    border: 1px solid #E2E2E2;
    margin-right: 10px;
} */


.competition-header__meta  .avatar-company {
    width: 60px;
    height: 30px;
    background-color: transparent;
    padding: 4px;
    /* border: 1px solid rgba(247, 247, 247, 1); */
}
.competition-header__meta .avatar-icon {
    width: 30px;
    height: 30px;
    color: #707070;
    background-color: transparent;
    padding: 4px;
    margin-left: 8px;
    /* border: 1px solid rgba(247, 247, 247, 1); */
}








.competition-header__info {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.competition-header__info .icon {
    width: 14px;
    height: auto;
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: inherit;
    
}

.competition-header__info .tflops-container {
    background-color: #C7D2D5;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    height: 24px;
    min-width: 155px;
    position: relative;
}

.competition-header__info .tflops-container .tflops-progress {
    background-color: #29B5C4;
    border: 1px solid #29B5C4;
    border-radius: 4px 0px 0px 4px;
    height: 22px;
    max-width: 100%;
    text-align: center;
    z-index: 1;
    position: absolute;
}

.competition-header__info .tflops-container .tflops-progress-text {
    text-align: center;
    position: absolute;
    display: flex;
    z-index: 2;
    width: 100%;
    height: 24px;
    color: #F7F7F7;
    font-size: 10px;
    line-height: 11px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.competition-header__title {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 24px;
    margin: 0px;
    padding: 0px;
    /* line-height: 28px; */
    font-style: italic;
    margin-bottom: 2px;
    color: #FFFFFF;
    letter-spacing: 0px;
    /* max-width: 450px; */
    overflow: hidden;
    /* text-overflow: ellipsis; */
    white-space: nowrap;
    text-shadow: 2px 2px 2px #474747;

}

.competition-header__title_small {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    font-style: italic;
    margin-bottom: 4px;
    margin-top: 0px;
    padding-top: 0px;
    color: #3576A7;
    letter-spacing: 0px;
    text-align: right;
    
}

.competition-header__subtitle {
    font-family: 'Roboto';
    font-weight: 500;
    font-style: italic;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: right;
    color: #707070;
    margin-bottom: 0px;
    margin-top: 0px;
    max-width: 458px;
    margin-left: 6px;
    
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
}


.competition-header__prize {
    text-align: right;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    margin-bottom: 6px;
    margin-top: 0px;
    color: var(--textPrimary);
    letter-spacing: 0px;
}

.competition-header__remaining {
    font-family: 'Roboto';
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
    color: #707070;
    margin-bottom: 0px;
}


.horizontal-list {
    display: inline-flex;
    align-items: center;
    list-style: none;
}

.horizontal-list-item {
    list-style: none;
    display: flex;
    align-items: center;
}

.competition-header__org {
    display: flex;
    align-items: center;
}

.horizontal-list-item--bullet:after {
    content: "\B7";
}

.horizontal-list-item--default:after {
    margin: 0 0px;
    opacity: .0;
}

.competition-header__org-thumbnail {
    margin-right: 20px;
    border: 1px solid;
    border-color: #dedfe0;
    border-radius: 5px;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: #FFFFFF;
}

.competition-header__org-thumbnail-image {
    border-width: 1px;
    border-color: #dedfe0;
    border-radius: 5px;
    width: 54px;
    object-fit: contain;
    vertical-align: bottom;
    padding: 2px;
}



