.avatar {
    width: 60px;
    height: 60px;
}

.save-dataset-box {
    display: flex;
    align-items: flex-start;
    border-radius: 5px;
    
    /* width: 400px; */
}
.save-dataset-box .inner-content {
    height: 362px;
}
.save-dataset-box .dataset-builder-title{
    color: #3576a7;
    font-size: 24px;
    font-weight: 700;
    font-style: italic;
    font-family: 'Roboto', Arial;
    margin-left: 40px;
}
.save-dataset-box .configurator-label {
    position: relative;
    top: -22px;
    margin-left: 20px;
    margin-right: auto;
    padding: 8px 16px;
    color: #3576a7;
    font-size: 12px;
    font-weight: 700;
    font-style: italic;
    font-family: 'Roboto', Arial;
    border-radius: 5px;
  }

.save-dataset-box .tab-panel {
    padding: 0px;
}


.iconButton {
    margin-left: 5px;
    color: #3576a7;
    width: 19px;
    height: 19px;
  }

.divider {
    height: 28px;
    margin: 4px;
}

.save-dataset-box .dataset-builder-section {
    height: 362px;
    padding-bottom: 20px;
}

.save-dataset-box .dataset-builder-section:not(:last-child) {
    border-right: 1px solid #e6e6e6;
}

.save-dataset-box .dataset-builder-section:not(:first-child) {
    padding-left: 20px;
}

.label-margin {
    margin-bottom: 10px;
}

.custom-checkbox-width {
    color: #3576a7;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Roboto', 'Arial';
    font-style: italic;
    width: calc(100%);
    margin-left: auto;
    margin-right: 10px;
}

.save-dataset-box .multi-column-list {
    height: 68%;
    flex: 0 0 100%;
    margin-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
}



.defect-label-tags {
    font-size: 10px;
    font-weight: 400;
    font-family: 'Roboto', Arial;
    font-style: italic;
    display: flex;
    flex-wrap: wrap;
    text-shadow: 0px 1px #00000033;
}

.defect-label-tags span {
    background-color: #e6e6e6;
    color: #ffffff;
    min-width: 60px;
    padding: 2px 6px;
    margin: 3px 3px; 
    text-align: center;
    transition: all 0.6s ease;
}

.defect-label-tags .nok {
    background-color: #e31010;
    color: #fff
}

.defect-label-tags .ok {
    background-color: #3576a7;
    color: #fff
}


.image-type-scale {
    color: #707070;
    padding: 2px 3px;
    margin: 3px 0px; 
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Roboto', Arial;
    font-style: italic;
}

.total-toggle-group {
    height: 18px;
}

.total-toggle-group button {
    font-size: 10px;
    text-transform: none;
    font-weight: 300;
    font-family: 'Roboto', Arial;
    min-width: 80px;
    color: #3576a7;
    border-color: #3576a7;
}

.total-toggle-group .selected-toggle {
    background-color: #3576a7;
    color: #fff;
    font-weight: 500;
    font-family: 'Roboto', Arial;
}

.total-toggle-group .total-toggle:hover {
    background-color: #3576a7;
    color: #fff;
    font-weight: 500;
    font-family: 'Roboto', Arial;
}

.grey-italic-ratio {
    color: #707070;
    padding: 2px 3px;
    margin: 3px 0px; 
    margin-left: 24px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Roboto', Arial;
    font-style: italic;
}

.grey-italic-text {
    color: #707070;
    padding: 0px 0px;
    margin: 2px 0px; 
    margin-left: 8px;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Roboto', Arial;
    font-style: italic;
}

.save-dataset-input-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    margin-right: 1px;
    margin-top: auto;
    position: fixed;
    bottom: 50px;
    right: 80px;
}

.save-dataset-input-box .save-dataset-info {
    color: rgba(112, 112, 112, 1);
    font-size: 8px;
    font-weight: 300;
    font-style: italic;
    font-family: 'Roboto', Arial;
}

.save-dataset-input {
    padding: 2px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 32px;
    border-radius: 5px;
  }

.save-dataset-input input {
        padding-left: 16px;
        padding-right: 16px;
        flex: 1;
        color: rgba(112, 112, 112, 1);
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        font-family: 'Roboto', Arial;
        min-width: 180px;
}


.save-dataset-input .save-dataset-btn  {
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    text-transform: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    font-family: 'Roboto', Arial;
    background-color: #3576a7;
    background-image: url('../../assets/icon-hover.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5px 16px;
    top: 0px;
    max-height: 31px;
    min-width: 108px;
}

.save-dataset-input .save-dataset-btn:hover {
    opacity: 0.9;
}

.save-dataset-input .save-dataset-btn.Mui-disabled {
    background-color: #3576a7;
    background-image: none;
    color: #ffffff;
}

.carousel-container img {
    cursor: zoom-in;
}
.image-zoom-out {
    cursor: zoom-out;
    width: 100px;

}

.image-zoom-out-width {
    transition: all 0.3s ease-out;
    width: 100%;
}