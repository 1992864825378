.activation-main {
    min-height: calc(100vh - 147px);
}

.activation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}

.activation .title {
    font-size: 48px;
    line-height: 60px;
    font-family: "Roboto";
    align-self: center;
    text-align: center;
    /* margin: 64px 0; */
    font-weight: 700;
    color: #3576a7;
}

.activation .sub-title {
    color: rgba(112, 112, 112, 1);
    line-height: 26px;
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    align-self: center;
    text-align: center;
    /* margin: 64px auto; */
}

.activation .form {
    padding: 64px;
    max-width: var(--max-width);
    width: 709px;
    min-height: 536px;
    height: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 25px 25px #00000014;
    border-radius: 12px;
    opacity: 1;
    backdrop-filter: blur(21px);
    -webkit-backdrop-filter: blur(21px);

}


.activation .form .error-message {
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0px;
    color: #E31010;
    opacity: 1;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
}




.activation .form .activation-username {
    margin-top: 10px;
}

.activation .form .activation-password {
    margin-top: 10px;
}

.activation .form .activation-button {
    width: 200px;
    line-height: 2.5;
    margin-top: 10px;
    margin-left: auto;
}
