.experiment-view .single-row {
    position: relative;
    padding-bottom: 40px;
    padding-top: 16px;
}


.experiment-view #first-experiment-row {

    padding-top: 60px;

}

.experiment-view:last-child .single-row::after {
    content: ' ';
    position: absolute;
    top: 115px;
    left: -10px;
    width: 3px;
    height: calc(100% - 114px);
    background-color: white;
}

.experiment-view .graph-header .heading {
    font-size: 12px;
    font-weight: 700;
    font-style: italic;
    color: var(--primary);
    font-family: 'Roboto', Arial;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}



.experiment-view .graph-box .graph-header .icon {
    width: 11px;
    height: 11px;
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: inherit;
    
}

.experiment-view .graph-header button {
    background-color: white;
    padding: 4px 4px;
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: var(--primary);
    min-width: 12px;
    border-radius: 3px;
    border: 1px solid rgba(230, 230, 230, 1);
}



.experiment-view .fullscreen-graph .graph-header {
    padding-right: 20px;

}

.experiment-view .graph-header .values {
    color: rgba(112, 112, 112, 1);
    font-weight: 400;
    font-style: italic;
    font-size: 12.00px;
    font-family: 'Roboto', Arial;
    letter-spacing: 0;
    text-decoration: none;
}

.experiment-view .graph-header .values::before {
    display: inline-block;
    content: "";
    width: 26px;
    transform: translateY(-2px);
    margin-right: 10px;
}

.experiment-view .graph-header .training::before {
    border-top: 1px solid #ffae15;
}

.experiment-view .graph-header .validation::before {
    border-top: 1px solid #3576a7;
}


.experiment-view .graph-header span.values {
    color: rgba(53, 118, 167, 1);
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    letter-spacing: 0;
    text-decoration: none;
    font-family: 'Roboto', Arial;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 125px;
}


.experiment-view .single-row .icon {
    width: 16px;
    height: 16px;
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: inherit;
}

.experiment-view .top-buttons {
    position: relative;
    top: 12px;
    padding: 0px 20px 0px 40px;
}



.experiment-view .top-buttons  .status-icon {
    color: var(--blue-status);
    font-size: 20px;
    margin-right: 6px;

}

.experiment-view .top-buttons .stopped {
    color: var(--red-status);
}


.experiment-view .top-buttons button {
    background-color: white;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
    height: 32px;
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: var(--primary);
    min-width: 40px;
}

.experiment-view .top-buttons button:nth-child(1) {
    color: var(--primary);
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    font-style: italic;
    text-align: left;
    text-transform: none;
    align-items: center;
    cursor: default;
    /* border-left: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px; */
    
}


.experiment-view .top-buttons .like-button {
    margin-right: 0px;
    color: var(--primary);
    padding-right: 0px;
    font-weight: 400;
}

.experiment-view .top-buttons .like-button .claps-count {
    font-size: 10px;
    font-family: 'Roboto';
    line-height: 13px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-left: 6px;
}
.experiment-view .top-buttons .like-button .like-icon {
    font-size: 16px;
    margin-right: 6px;
    color: var(--primary);
    padding-right: 2px;
    /* border-right: 1px solid rgba(230, 230, 230, 1); */
}


.experiment-view .heading-model {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    font-style: italic;
    color: var(--primary);
    font-family: 'Roboto', Arial;
    margin-right: auto;
    text-align: left;
}

.experiment-view .heading-model span {
    text-align: left;
    letter-spacing: 0px;
    color: rgba(112, 112, 112, 1);
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
}

.experiment-view .heading {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    color: var(--primaryText);
    font-family: 'Roboto', Arial;
    margin-right: auto;
}

.experiment-view .normal-text {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    color: rgba(112, 112, 112, 1);
    font-family: 'Roboto', Arial;
    letter-spacing: 0px;
    vertical-align: bottom;
}

.experiment-view .inner-view {
    border: 1px solid #e2e2e2;
    padding: 26px 8px 0px 30px;
    margin-left: 20px;
}

.experiment-view .inner-view .avatar-container {
    text-align:center; 
    position: relative;
    width: 50px;
    margin-left: -60px;
    margin-top: 20px;
}


.experiment-view .inner-view .avatar {
    width: 44px;
    height: 44px;
    display: inline-flex;
    z-index: 1;
    margin-left: 8px;
    margin-top: -6px;
    border: 1px solid #3576A7;
    padding: 1px;
    cursor: pointer;
    /* background-color: white; */
}



.experiment-view .inner-view .avatar img {
    border-radius: 50%;
}

.experiment-view .inner-view .avatar-container::after { 
    content:'';
    position: absolute;
    left: -1px;
    right: 0;
    top: 15px;
    height: 1px;
    width: 88%;
    background: #e2e2e2;
    z-index:0;
}

.experiment-view:last-child .inner-view .avatar-container::after {
    left: 0px;
}


.experiment-view .inner-view .details-container {
    max-width: 100%; 
    /* max-width: calc(100% - 50px); */
    flex-basis: 100%;
    padding-left: 20px;
    padding-bottom: 0px;
}

.experiment-view .inner-view .details-container .objective-box {
    height: 100%;
}

.experiment-view .inner-view .details-container .objective-box .MuiInputBase-root {
    height: 100%;
    margin-bottom: 20px;
}

.experiment-view .inner-view .details-container .objective-box .MuiInputBase-root textarea {
    height: inherit;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Roboto', Arial;
    font-style: italic;
    color: #707070;
    line-height: 14px;
}

.experiment-view .inner-view .details-container .objective-box .MuiInputBase-root textarea:not(.Mui-disabled) {
    background-color: #f7f7f7;
    padding: 5px;
}
.experiment-view .inner-view .details-container .fullscreen-button {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2001;
}

.experiment-view .inner-view .details-container .graph-box {
    display: flex;
    flex-direction: column;
    padding: 8px;
    position: relative;
}

.experiment-view .inner-view .details-container .fullscreen-graph {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 100%;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 2000;
    padding: 24px;
}


.experiment-view .inner-view .details-container .control-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin: 16px 8px 0px 0px;
}

.experiment-view .inner-view .details-container .control-box .color-container {
    background: #f7f7f799 0% 0% no-repeat padding-box;
    height: 42px;
    align-self: center;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    margin-right: 6px;
    padding-left: 4px;
    padding-right: 4px;
}

.experiment-view .inner-view .details-container .control-box .cycle-no {
    color: var(--primary);
    font-weight: normal;
    font-style: italic;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Roboto', Arial;
    letter-spacing: 0;
    text-decoration: none;
}

.experiment-view .inner-view .details-container .control-box .total-cycles {
    text-align: center;
    letter-spacing: 0.00px;
    color: rgba(53, 118, 167, 1);
    -webkit-text-stroke: unset;
    font-size: 12.00px;
    line-height: 14px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
}


.experiment-view .inner-view .details-container .control-box .rounds {
    background: #E6E6E6 0% 0% no-repeat padding-box;
    padding: 8px;
    border-radius: 2px;
    margin: 0 8px 0 8px;
}

.experiment-view .inner-view .details-container .control-box .est-time-title {
    font-size: 10px;
    font-weight: normal;
    font-style: italic;
    color: rgba(112, 112, 112, 1);
    font-family: 'Roboto', Arial;
}
.experiment-view .inner-view .details-container .control-box .est-time {
    text-align: left;
    letter-spacing: 0.00px;
    color: rgba(53, 118, 167, 1);
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    padding: 0px 10px;
}

.experiment-view .inner-view .details-container .detailed-list {
    padding: 0 8px;
    flex-wrap: wrap;
    margin-top: 24px;
    justify-content: space-between;
}


.experiment-view .inner-view .details-container .detailed-list {
    padding: 0 8px;
    flex-wrap: wrap;
    margin-top: 8px;
    justify-content: space-between;
}

.experiment-view .inner-view .details-container .detailed-list .item {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 4px;
}

.experiment-view .inner-view .details-container .detailed-list .item:nth-child(1),
.experiment-view .inner-view .details-container .detailed-list .item:nth-child(5n+5) {
    padding-left: 0px;
    padding-right: 40px;
}
.experiment-view .inner-view .details-container .detailed-list .item:nth-child(4n+4) {
    padding-right: 0px;
    padding-left: 40px;
}

.experiment-view .inner-view .details-container .detailed-list .item:last-child:not(:nth-child(4n+4)) {
    margin-right: auto;
    
}

.experiment-view .inner-view .details-container .detailed-list .item .head {
    width: 60%;
    text-align: left;
    letter-spacing: 0.00px;
    color: rgba(112, 112, 112, 1);
    font-size: 12.00px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;

}

.experiment-view .inner-view .details-container .detailed-list .item .normal-text {
    width: 40%;
    text-align: right;
    letter-spacing: 0.00px;
    color: rgba(53, 118, 167, 1);
    font-size: 12.00px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
}


/* Optimun cycle css 
/////////////////////////////////////////////
*/


.experiment-view .inner-view .details-container .optimum-cycle-head {
    text-align: left;
    font: normal normal medium 12px/16px Roboto;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    margin-top: 4px;
    margin-left: 8px;
    margin-bottom: 8px;
    color: #707070;
    display: flex;


}

.experiment-view .inner-view .details-container .optimum-cycle-head .optimum {
    color: #5DD39E;
}

.experiment-view .inner-view .details-container .optimum-cycle-head .selected {
    color: rgba(53, 118, 167, 1);
}

.experiment-view .inner-view .details-container .optimum-cycle {
    padding: 0 8px;
    padding-right: 0px;
    flex-wrap: nowrap;
    margin-top: 8px;
    justify-content: space-between;
}



.experiment-view .inner-view .details-container .optimum-cycle .item {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 4px;
    max-width: 189px;
    flex-basis: 20%;
}

.experiment-view .inner-view .details-container .optimum-cycle .item .item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;

}


.experiment-view .inner-view .details-container .optimum-cycle .item:nth-child(1),
.experiment-view .inner-view .details-container .optimum-cycle .item:nth-child(5n+7) {
    padding-left: 0px;
    /* padding-right: 8px; */
}
.experiment-view .inner-view .details-container .optimum-cycle .item:nth-child(4n+6) {
    padding-right: 0px;
    padding-left: 8px;
}

.experiment-view .inner-view .details-container .optimum-cycle .item:last-child:not(:nth-child(4n+6)) {
    margin-left: auto;
    padding-right: 8px;
    max-width: 262px;
    flex-basis: 35%;
    
}

.experiment-view .inner-view .details-container .optimum-cycle .item .head {
    width: 60%;
    text-align: left;
    letter-spacing: 0.00px;
    color: rgba(112, 112, 112, 1);
    font-size: 12.00px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;

}

/* .experiment-view .inner-view .details-container .optimum-cycle .item:nth-child(4n+6) .head {
    text-align: left;
} */


.experiment-view .inner-view .details-container .optimum-cycle .item .optimum-text {
    width: 40%;
    text-align: right;
    letter-spacing: 0px;
    color: #5DD39E;
    font-size: 12.00px;
    line-height: 16px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
}

.experiment-view .inner-view .details-container .optimum-cycle .item .selected-text {
    width: 40%;
    text-align: right;
    letter-spacing: 0px;
    color: #3576A7;
    font-size: 12.00px;
    line-height: 16px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
}

/* .experiment-view .inner-view .details-container .optimum-cycle .item:nth-child(4n+6) .normal-text {
    text-align: right;
} */








.experiment-view .bottom-buttons {
    position: relative;
    bottom: 13px;
    padding: 0px 20px 0px 40px;
    z-index: 50;
}

.experiment-view .bottom-buttons .buttons-list button:first-child {
    border-left: 1px solid rgba(230, 230, 230, 1);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.experiment-view .bottom-buttons .buttons-list button:last-child {
    border-right: 1px solid rgba(230, 230, 230, 1);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.experiment-view .bottom-buttons .buttons-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.experiment-view .bottom-buttons .buttons-list button {
    background-color: white;
    padding: 8px 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: var(--primary);
    min-width: 29px;
    /* max-width: 28px; */
    height: 28px;
    border-radius: 0px;
    border: 1px solid rgba(230, 230, 230, 1);
    border-left: 0;
}

.experiment-view .bottom-buttons .buttons-list button .title {
    margin-left: 8px;
}



.experiment-view .bottom-buttons .buttons-list .selected {
    background-color: var(--primary);
    color: white;
    border-color: var(--primary);
}

.experiment-view .bottom-buttons .buttons-list .selected .icon {
    color: #E6E6E6;
}

.experiment-view .bottom-buttons .buttons-list .selected img {
    filter: saturate(0%) brightness(1000%) contrast(100%);
}

.experiment-view .bottom-buttons .buttons-list .icon {
    width: auto;
    height: 14px;
}










.experiment-view .inner-view .details-container .control-box .buttons-list {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.experiment-view .inner-view .details-container .control-box .buttons-list button {
    background-color: white;
    padding: 8px 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: var(--primary);
    min-width: 28px;
    /* max-width: 28px; */
    height: 28px;
    border-radius: 0px;
    border: 1px solid rgba(230, 230, 230, 1);
    border-left: 0;
}

.experiment-view .inner-view .details-container .control-box .buttons-list button .title {
    margin-left: 8px;
}



.experiment-view .inner-view .details-container .control-box .buttons-list .selected {
    background-color: var(--primary);
    color: white;
    border-color: var(--primary);
}

.experiment-view .inner-view .details-container .control-box .buttons-list .selected .icon {
    color: #E6E6E6;
}

.experiment-view .inner-view .details-container .control-box .buttons-list .selected img {
    filter: saturate(0%) brightness(1000%) contrast(100%);
}

.experiment-view .inner-view .details-container .control-box .buttons-list .icon {
    width: auto;
    height: 14px;
}


.experiment-view .inner-view .details-container .control-box .buttons-list button.first-child {
    border-left: 1px solid rgba(230, 230, 230, 1);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.experiment-view .inner-view .details-container .control-box .buttons-list button.last-child {
    border-right: 1px solid rgba(230, 230, 230, 1);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.experiment-view .inner-view .details-container .control-box .buttons-list {
    background-color: transparent;
}

.experiment-view .inner-view .details-container .control-box .buttons-list .icon {
    width: 20px;
    height: 20px;
}

.experiment-view .inner-view .details-container .control-box .buttons-list button {
    background-color: transparent;
    font-size: 12px;
    padding: 4px 4px;
}


.experiment-view .inner-view .details-container .control-box .legend {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    color: rgba(112, 112, 112, 1);
    font-family: 'Roboto', Arial;
    text-align: center;
    margin: 0 8px;
}

.experiment-view .inner-view .details-container .control-box .legend::before {
    display: inline-block;
    content: "";
    width: 26px;
    transform: translateY(-2px);
    margin-right: 10px;
}
.experiment-view .inner-view .details-container .control-box .training::before {
    border-top: 1px solid #ffae15;
}

.experiment-view .inner-view .details-container .control-box .validation::before {
    border-top: 1px solid #3576a7;
}

.experiment-view .inner-view .details-container .graph-box .epoch {
 align-self: flex-end;
 margin-right: 20px;
 font-size: 10px;
 margin-top: -8px;
 margin-bottom: -8px;
}

.experiment-view .inner-view .details-container .graph-box  .trainingAccuracy,
.experiment-view .inner-view .details-container .graph-box  .trainingLoss {
    color: #ffae15;
}

.experiment-view .inner-view .details-container .graph-box  .validationAccuracy,
.experiment-view .inner-view .details-container .graph-box  .validationLoss {
    color: #3576a7;
}

.experiment-view .single-row .bottom-sheets {
    border: 1px solid #e2e2e2;
    border-top: 0;
    margin-left: 20px;
    padding: 40px;
}



.defect-bar-tooltip {
    display: flex;
    flex-direction: column;
    padding: 8px;
    font-size: 10px;
    text-align: left;

}

.defect-bar-tooltip span {
    color: rgba(112, 112, 112, 1);
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    margin-right: 10px;
    text-align: left;

 }


.defect-bar-tooltip .crack {
    color: rgba(65, 14, 82, 1);
}

.defect-bar-tooltip .inclusion {
    color: rgba(255, 156, 41, 1);
}

.defect-bar-tooltip .porosity {
    color: rgba(125, 208, 162, 1);
}

.text-10 {
    font-size: 10px;
}