.experiment-view {
    border-left: 1px solid #e2e2e2;
    margin: -6px 0px 0px 10px;
    padding-left: 9px;
    width: 100%;
    cursor: default;
}

.experiment-view:last-child {
    border-left: 1px solid #e2e2e2;
    margin: 0px 0px 8px 10px;
    padding-left: 8px;
    cursor: default;
}


.experiment-view .ring-container {
    position: relative;
    top: -40px;
    left: -40px;
    margin-left: 20px;
    margin-right: 6px;
}
