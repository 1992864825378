.user-popup {
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    min-width: 180px;
    background-color: #ffffff;

}

.user-popup .user-header {
    border-radius: 4px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    text-align: left;
    letter-spacing: 0.00px;
    color: #F7F7F7;
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    text-decoration: none;
    background-image: url('../../assets/icon-hover.png');
    height: 37px;
    padding: 0px 10px 0px 20px;
    align-items: center;
}

.user-popup .user-header span {
    vertical-align: middle;
}
.user-popup .user-header .trainings{
    color: #F7F7F7;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    font-family: 'Roboto', Arial;
}

.user-popup .user-header .close-button {
    background-color: #F7F7F7;
    color: var(--primary);
    min-width: 16px;
    max-width: 16px;
    max-height: 16px;
}
.user-popup .user-header .close-button .icon {
    color: var(--primary);
    width: 10px;
    height: 10px;
}


.user-popup .user-ds-info {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 12px;
}

.user-popup .user-ds-info .item {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-left: 0px;
    align-items: baseline;
}

.user-popup .user-ds-info .text {
    margin-top: 0px;
    margin-bottom: 0px;
}

.user-popup .user-ds-info .datasets {
    text-align: right;
    letter-spacing: 0.00px;
    color: rgba(53, 118, 167, 1);
    -webkit-text-stroke: unset;
    font-size: 10.00px;
    font-weight: 500;
    font-style: italic;
    font-family: roboto_mediumitalic, Arial;
    text-decoration: none;
    margin-right: 4px;
}

.user-popup .user-ds-info .text span {
    text-align: left;
    letter-spacing: 0.00px;
    color: rgba(112, 112, 112, 1);
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    font-family: roboto_italic, Arial;
    text-decoration: none;
}

.user-popup .user-ds-info .icon-container {
    margin-right: 5px;
}
.user-popup .user-ds-info .icon {
    height: 8px;
    width: 8px;
    color: #707070;
}



.user-popup .user-actions {
    padding-left: 20px;
    padding-right: 20px;
}

.user-popup .user-actions .item {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-left: 0px;
    align-items: baseline;
}

.user-popup .user-actions .text {
    margin-top: 0px;
    margin-bottom: 0px;
}

.user-popup .user-actions .datasets {
    text-align: right;
    letter-spacing: 0.00px;
    color: rgba(53, 118, 167, 1);
    -webkit-text-stroke: unset;
    font-size: 10.00px;
    font-weight: 500;
    font-style: italic;
    font-family: roboto_mediumitalic, Arial;
    text-decoration: none;
    margin-right: 4px;
}

.user-popup .user-actions .text span {
    text-align: left;
    letter-spacing: 0.00px;
    color: rgba(112, 112, 112, 1);
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    font-family: roboto_italic, Arial;
    text-decoration: none;
}

.user-popup .user-actions .icon-container {
    min-width: 24px;
}
.user-popup .user-actions .icon {
    height: 8px;
    width: 8px;
    color: #707070;
}


.user-popup .user-container {
    padding: 8px;
    border-top: 1px solid #E6E6E6;
}


.user-popup .user-container .avatar {
    width: 32px;
    height: 32px;
    margin: auto;
    border: 1px solid #3576A7;
    padding: 1px;
    margin-right: 0px;

}
.user-popup .user-container .avatar img {
    border-radius: 50%;
}

.user-popup .user-container .user-name {
    color: var(--unnamed-color-3576a7);
    text-align: left;
    font-family: "Roboto", Arial;
    font-size: 10px;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0px;
    color: var(--primary);
    opacity: 1;
}

.user-popup .user-container .user-email {
    font-family: "Roboto", Arial;
    text-align: left;
    letter-spacing: 0.00px;
    color: rgba(112, 112, 112, 1);
    font-size: 10.00px;
    font-weight: 400;
    font-style: italic;
}