.competition-popup {
    min-width: 203px;
}

.competition-popup .competition-header {
    text-align: left;
    letter-spacing: 0.00px;
    color: rgba(112, 112, 112, 1);
    -webkit-text-stroke: unset;
    font-size: 12.00px;
    line-height: 14px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto';
    text-decoration: none;
}

.competition-popup .competitors-list .competitor-row {
    padding: 8px 0px;
    color: rgba(53, 118, 167, 1);
    -webkit-text-stroke: unset;
    font-size: 10.00px;
    font-weight: normal;
    font-style: italic;
    font-family: 'Roboto', Arial;
    text-decoration: none;
    text-align: left;
    align-items: center;
}

.competition-popup .competitors-list > div:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
}

.competition-popup .competitors-list .competitor-row img {
    width: 19px;
    height: auto;
}

.competition-popup .competitors-list .competitor-row .competitor-name {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.competition-popup .tflops-container {
    background-color: rgba(112, 112, 112, 1);
    border-radius: 4px;
    height: 24px;
    position: relative;
}

.competition-popup .tflops-container .tflops-progress {
    background-color: rgba(75, 189, 204, 1);
    border: 1px solid rgba(75, 189, 204, 1);
    border-radius: 4px 0px 0px 4px;
    height: 24px;
    width: 0%;
    max-width: 100%;
    text-align: center;
    z-index: 1;
    position: absolute;
}

.competition-popup .tflops-container .tflops-progress-text {
    text-align: center;
    position: absolute;
    display: flex;
    z-index: 2;
    width: 100%;
    height: 24px;
    color: rgba(247, 247, 247, 1);
    font-size: 10px;
    line-height: 11px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}