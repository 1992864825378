.claps-container {
    margin: 0 auto;
}
.claps-container button {
    float: left;
    margin: 0 0px;
    cursor: pointer !important;
    background-color: #fff;
    padding-left: 12px;
    padding-right: 12px;
}
.claps-container .clap {
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 50%;
    border: none;
    background: transparent;
}
.claps-container .clap i {
    width: 20px;
    height: 20px;
    color: #fff;
    background: var(--primary);
    font-size: 30px;
    border-radius: 50%;
    line-height: 46px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -10px 0 0 -10px;
}
.claps-container .clap-active i {
    animation: animationThumb ease-in .3s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    -webkit-animation: animationThumb linear .3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: animationThumb linear .3s;
    -moz-animation-iteration-count: infinite;
    -moz-transform-origin: 50% 50%;
    -o-animation: animationThumb linear .3s;
    -o-animation-iteration-count: infinite;
    -o-transform-origin: 50% 50%;
    -ms-animation: animationThumb linear .3s;
    -ms-animation-iteration-count: infinite;
    -ms-transform-origin: 50% 50%;
}
.claps-container .clap:focus {
    outline: 0;
}
.claps-container .clap-animation {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 26%;
    top: 58%;
    margin: -12px 0 0 -7px;
    opacity: 0;
}
.claps-container .clap-animation-active {
    animation: ClapsAnimationFrames linear .3s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    -webkit-animation: ClapsAnimationFrames linear .3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: ClapsAnimationFrames linear .3s;
    -moz-animation-iteration-count: infinite;
    -moz-transform-origin: 50% 50%;
    -o-animation: ClapsAnimationFrames linear .3s;
    -o-animation-iteration-count: infinite;
    -o-transform-origin: 50% 50%;
    -ms-animation: ClapsAnimationFrames linear .3s;
    -ms-animation-iteration-count: infinite;
    -ms-transform-origin: 50% 50%;
}
.claps-container .clap-animation-item {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.claps-container .clap-animation-item:nth-child(0) {
    transform: rotate(0deg);
}
.claps-container .clap-animation-item:nth-child(1) {
    transform: rotate(45deg);
}
.claps-container .clap-animation-item:nth-child(2) {
    transform: rotate(90deg);
}
.claps-container .clap-animation-item:nth-child(3) {
    transform: rotate(135deg);
}
.claps-container .clap-animation-item:nth-child(4) {
    transform: rotate(180deg);
}
.claps-container .clap-animation-item:nth-child(5) {
    transform: rotate(225deg);
}
.claps-container .clap-animation-item:nth-child(6) {
    transform: rotate(270deg);
}
.claps-container .clap-animation-item:nth-child(7) {
    transform: rotate(315deg);
}
.claps-container .clap-animation-item:nth-child(2n+1):before {
    background-color: transparent;
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 6px solid var(--primary);
    transform: rotate(45deg);
    border-radius: 0;
}
.claps-container .clap-animation-item:before {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    content: '';
    background-color: #4bbdcc;
    position: absolute;
    left: 0;
    top: 0;
}
@keyframes ClapsAnimationFrames {
    0% {
        opacity: 1;
        transform: scaleX(1.00) scaleY(1.00);
   }
    100% {
        opacity: 0;
        transform: scaleX(1.30) scaleY(1.30);
   }
}
@-moz-keyframes ClapsAnimationFrames {
    0% {
        opacity: 1;
        -moz-transform: scaleX(1.00) scaleY(1.00);
   }
    100% {
        opacity: 0;
        -moz-transform: scaleX(1.30) scaleY(1.30);
   }
}
@-webkit-keyframes ClapsAnimationFrames {
    0% {
        opacity: 1;
        -webkit-transform: scaleX(1.00) scaleY(1.00);
   }
    100% {
        opacity: 0;
        -webkit-transform: scaleX(1.30) scaleY(1.30);
   }
}
@-o-keyframes ClapsAnimationFrames {
    0% {
        opacity: 1;
        -o-transform: scaleX(1.00) scaleY(1.00);
   }
    100% {
        opacity: 0;
        -o-transform: scaleX(1.30) scaleY(1.30);
   }
}
@-ms-keyframes ClapsAnimationFrames {
    0% {
        opacity: 1;
        -ms-transform: scaleX(1.00) scaleY(1.00);
   }
    100% {
        opacity: 0;
        -ms-transform: scaleX(1.30) scaleY(1.30);
   }
}
@keyframes animationThumb {
    0% {
        transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
   }
    28% {
        transform: rotate(-28deg) scaleX(1.26) scaleY(1.25);
   }
    74% {
        transform: rotate(0deg) scaleX(1.09) scaleY(1.09);
   }
    100% {
        transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
   }
}
@-moz-keyframes animationThumb {
    0% {
        -moz-transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
   }
    28% {
        -moz-transform: rotate(-28deg) scaleX(1.26) scaleY(1.25);
   }
    74% {
        -moz-transform: rotate(0deg) scaleX(1.09) scaleY(1.09);
   }
    100% {
        -moz-transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
   }
}
@-webkit-keyframes animationThumb {
    0% {
        -webkit-transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
   }
    28% {
        -webkit-transform: rotate(-28deg) scaleX(1.26) scaleY(1.25);
   }
    74% {
        -webkit-transform: rotate(0deg) scaleX(1.09) scaleY(1.09);
   }
    100% {
        -webkit-transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
   }
}
@-o-keyframes animationThumb {
    0% {
        -o-transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
   }
    28% {
        -o-transform: rotate(-28deg) scaleX(1.26) scaleY(1.25);
   }
    74% {
        -o-transform: rotate(0deg) scaleX(1.09) scaleY(1.09);
   }
    100% {
        -o-transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
   }
}
@-ms-keyframes animationThumb {
    0% {
        -ms-transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
   }
    28% {
        -ms-transform: rotate(-28deg) scaleX(1.26) scaleY(1.25);
   }
    74% {
        -ms-transform: rotate(0deg) scaleX(1.09) scaleY(1.09);
   }
    100% {
        -ms-transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
   }
}
