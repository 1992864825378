.competition-experiments {
    margin-bottom: 8px;
    max-height: 100%;
    overflow: hidden;
}
.competition-experiments .table  {
    background-color: #ffffff;
    border-collapse: separate;
    border-spacing: 0 6px;
}

.competition-experiments .table .sticky {
    /* background-color: rgba(53, 118, 167, 1); */
    z-index: 2000;
}

.competition-experiments .table  thead > tr > th {
    color: rgba(53, 118, 167, 1);
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    font-size: 12px;
    border-bottom: 0px;
    padding: 2px 0px 2px 6px;
    line-height: 1rem;
    background-color: #ffffff;
    z-index: 20;
    text-align: left;
}

.competition-experiments .table thead > tr > th:first-child {
    padding-left: 20px;
}

.competition-experiments .table thead > tr > th:nth-child(5),
.competition-experiments .table thead > tr > th:nth-child(6){
    text-align: right;
    padding-right: 8px;
}


.competition-experiments .table  .empty-td {
    border: 0px;
}




.competition-experiments .table  td {
    padding: 2px 8px;
}



.competition-experiments > .table > tbody > tr > td:first-child {
    border-left-width: 1px;
    border-radius: 5px 0 0 5px;
    padding-left: 20px;
    text-align: left;
}
.competition-experiments > .table > tbody > tr > td:last-child {
    border-right-width: 1px;
    border-radius: 0 5px 5px 0;
    padding-right: 10px;
}


.competition-experiments > .table > tbody > tr > td {
    color: var(--textPrimary);
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
    font-family: 'Roboto';
    border-width: 1px 0;
    border-style: solid;
    border-color: rgba(230, 230, 230, 1);
    cursor: pointer;
    text-align: left;
    padding-top: 6px;
    padding-bottom: 5px;
}

.competition-experiments .table > tbody > tr > td:nth-child(5),
.competition-experiments .table > tbody > tr > td:nth-child(6) {
    text-align: right;
    padding-right: 34px;
}

.competition-experiments .table  .sort-label {
    color: rgba(53, 118, 167, 1);
}

.competition-experiments .table  .sort-icon {
    color: rgba(53, 118, 167, 1);
    opacity: 0.7;
}

.competition-experiments .table  .sort-icon-active {
    color: rgba(53, 118, 167, 1);
    opacity: 1;
    font-weight: bold;
}

.competition-experiments .table  .sort-icon-active > svg > path {
    color: rgba(53, 118, 167, 1);
}

.competition-experiments .table  .icon-button {
    padding: 4px;
    color: rgba(53, 118, 167, 1);

}

.competition-experiments .table  .status-icon {
    color: var(--blue-status);
    font-size: 20px;
}

.competition-experiments .table .stopped {
    color: var(--red-status);
}

.competition-experiments .table .exp-id {
    display: inline;
}

.competition-experiments .table .icon {
    width: 20px;
    height: 20px;
}

.competition-experiments .table  .avatars-group {
    justify-content: flex-start;
}

.competition-experiments .table  .avatars-group .avatar-small {
    width: 24px;
    height: 24px;
    color: #707070;
    font-size: 8px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto",Arial;
    background-color: #f7f7f7;
    border: 1px solid #E2E2E2;
    
}

.competition-experiments .table  .avatars-group .avatar-small:first-child {
    width: 24px;
    height: 24px;
    color: #707070;
    font-size: 8px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto",Arial;
    background-color: #f7f7f7;
    border: 1px solid #E2E2E2;
    margin-right: 10px;
}


.competition-experiments .table  .avatar-company {
    width: 60px;
    height: 30px;
    background-color: transparent;
    padding: 4px;
    /* border: 1px solid rgba(247, 247, 247, 1); */
}
.competition-experiments .table .avatar-icon {
    width: 30px;
    height: 30px;
    color: #707070;
    background-color: transparent;
    padding: 4px;
    margin-left: 8px;
    /* border: 1px solid rgba(247, 247, 247, 1); */
}

.competition-experiments .table .avatar-icon:hover {
    /* border: 1px solid rgba(247, 247, 247, 1); */
}

.competition-experiments .table  tbody > tr.MuiTableRow-hover:hover > td .avatar-icon{
    color: #ffffff;

}

.competition-experiments .table  .avatar-company img {
    object-fit:contain
}


.competition-experiments .table  .rating-stars {
    color: rgba(53, 118, 167, 1);
}


/* CSS for Row hover */

.competition-experiments .table  tbody > .MuiTableRow-hover:hover {
    background-color: rgba(53, 118, 167, 1);
}
.competition-experiments .table  tbody > tr.MuiTableRow-hover:hover > td {
    color: white;
}
.competition-experiments .table  tbody > tr.MuiTableRow-hover:hover > td .rating-stars {
    color: white;
}
.competition-experiments .table  tbody > tr.MuiTableRow-hover:hover > td .icon-button {
    color: white;
}

.competition-experiments .table  tbody > tr.MuiTableRow-hover:hover > td .icon {
    color: white;
    filter: saturate(0%) brightness(1000%) contrast(100%);
}

.competition-experiments .table  tbody > tr.MuiTableRow-hover:hover > td .MuiCircularProgress-colorPrimary {
    color: white;
}
.competition-experiments .table  tbody > tr.MuiTableRow-hover:hover > td .Public-tag {
    color: white;
    background-color: #39CD97;
}

.competition-experiments .table  tbody > tr.MuiTableRow-hover:hover > td .Enterprise-tag {
    background-color: #430449;
    color: #fff;
}

.competition-experiments .table  tbody > tr.MuiTableRow-hover:hover > td .Global-tag {
    background-color: #FFA700;
    color: #fff;
}

.competition-experiments .table  tbody > tr.MuiTableRow-hover:hover > td .competition-tag {
    background-color: #4BBDCC;
    color: #fff;

}



/* CSS for Row selected */
.competition-experiments .table  tbody > .Mui-selected {
    background-color: rgba(53, 118, 167, 1);
}
.competition-experiments .table  tbody > .Mui-selected:hover {
    background-color: rgba(53, 118, 167, 1);
}
.competition-experiments .table  tbody > tr.Mui-selected> td {
    color: white;
}
.competition-experiments .table  tbody > tr.Mui-selected > td .rating-stars {
    color: white;
}
.competition-experiments .table  tbody > tr.Mui-selected > td .icon-button {
    color: white;
}

.competition-experiments .table  tbody > tr.Mui-selected > td .status-icon {
    font-size: 20px;
}

.competition-experiments .table  tbody > tr.Mui-selected > td .avatar-icon{
    color: white;
}

.competition-experiments .table  tbody > tr.Mui-selected > td .icon {
    color: white;
    filter: saturate(0%) brightness(1000%) contrast(100%);
}

.competition-experiments .table  tbody > tr.Mui-selected > td .MuiCircularProgress-colorPrimary {
    color: white;
}

.competition-experiments .table  tbody > tr.Mui-selected > td .Public-tag {
    color: white;
    background-color: #39CD97;
}

.competition-experiments .table  tbody > tr.Mui-selected > td .Enterprise-tag {
    background-color: #430449;
    color: #fff;
}

.competition-experiments .table  tbody > tr.Mui-selected > td .Global-tag {
    background-color: #FFA700;
    color: #fff;
}

.competition-experiments .table  tbody > tr.Mui-selected > td .competition-tag {
    background-color: #4BBDCC;
    color: #fff;

}



.competition-experiments .table .experiment-container {
    padding-top: 0px !important;
    max-height: 100%;
    cursor: default;
    position: relative;
    /* overflow: scroll; */
}

.competition-experiments .table  tbody > tr > td .tag {
    display: flex;
   align-items: center;
    height: 19px;
    font-size: 10px;
    line-height: 11px;
    font-weight: normal;
    font-family: 'Roboto';
    font-style: italic;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 4px 10px;
    text-transform: capitalize;
    border: 1px solid #efefee;
}

.competition-experiments .table  tbody > tr > td .competition-tag {
    color: #4BBDCC;
    border-color: #4BBDCC;

}

.competition-experiments .table  tbody > tr > td .competition-tag:hover {
    opacity: 0.7;
}

.competition-experiments .table  tbody > tr > td .Enterprise-tag {
    color: #430449;
    border-color: #430449;
}

.competition-experiments .table  tbody > tr > td .Global-tag {
    color: #FFA700;
    border-color: #FFA700;
}

.competition-experiments .table  tbody > tr > td .Public-tag {
    color: #39CD97;
    border-color: #39CD97;
}



.competition-experiments .ring-container {
    position: relative;
    top: -30px;
    left: -20px;
}

.competition-experiments .circle {
    width: 13px;
    height: 13px;
    background-color: var(--blue-status);
    border-radius: 50%;
    position: absolute;
    top: 24px;
    left: 24px;
}

.competition-experiments .ringring {
    border: 3px solid var(--blue-status);
    border-radius: 30px;
    height: 25px;
    width: 25px;
    position: absolute;
    left: 18px;
    top: 18px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite; 
    opacity: 0.0
}

@keyframes pulsate {
    0% {transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {transform: scale(1.2, 1.2); opacity: 0.0;}
}






.competition-experiments .select-drop-down-container {
    border: 0px;
    margin-right: 6px;
}

.competition-experiments .select-drop-down-container::before {
    border: 0px;
}

.competition-experiments .select-drop-down-container::hover {
    border: 0px;
}

.competition-experiments .sort-by {
    text-align: left;
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0px;
    color: #909B9D;
    text-transform: uppercase;
}

.competition-experiments .sort-by-label {
    text-align: left;
    font-family: "Roboto";
    font-weight: normal;
    font-style: normal;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0px;
    color: #909B9D;
    margin-left: 24px;
    margin-right: 12px;
}




.competition-experiments .select-drop-down-button {
    border: 0px;
    color: #3576a7;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Roboto', Arial;
    font-style: italic;
    background-color: #f7f7f7;
    text-align: center;
    min-width: 12px;
    height: 20px;
    width: 20px;
    margin-right: 6px;
    padding-right: 10px;
    border-radius: 2px;
    margin-bottom: 0px;
    line-height: 20px;
}


.competition-experiments .select-drop-down {
    border: 0px;
    color: #3576a7;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    background-color: #f7f7f7;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px !important; 
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 2px;
    min-width: 40px;
    height: 20px;
    line-height: 20px;
}

.competition-experiments .select-drop-down.Mui-disabled {
    color: rgba(0, 0, 0, 0.38);
}

.competition-experiments .select-drop-down-menu {
    border: 0px;
    border-radius: 2px;
}

.competition-experiments .select-drop-down-menu ul {
    border: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.competition-experiments .select-drop-down-menu ul > li{
    border: 0px;
    color: #3576a7;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    background-color: #f7f7f7;
    text-align: center;
    cursor: pointer;
    
}
.competition-experiments .select-drop-down-menu ul > li.Mui-selected, .select-drop-down-menu ul > li.Mui-selected:hover {
    background-color: #3576a7;
    color: #fff;
}
.competition-experiments .select-drop-down-menu ul > li:hover {
    background-color: #3576a7;
    color: #fff;
}

.competition-experiments .select-drop-down-icon {
    color: #3576a7;
    font-size: 14px;
    font-weight: lighter;
    font-family: 'Roboto', Arial;
    top: 3px;

}