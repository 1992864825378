.sustainability-container {
    margin: 0 auto;
}
.sustainability-container button {
    /* float: left; */
    margin: 0 5px;
    cursor: pointer !important;
    background-color: #fff;

}

.sustainability-container > div {
    display: flex;
    align-items: center;
    width: 100%;
    /* padding: 8px 12px; */
    /* background-color: white; */
    /* border: 1px solid #E6E6E6; */
    /* border-radius: 3px;
    height: 32px; */
    /* font-size: 10px; */
    /* font-weight: 400;
    font-style: italic;
    text-transform: none; */
    /* min-width: 40px; */
    /* border-bottom-right-radius: 1px;
    border-top-right-radius: 1px; */
}
.sustainability-container .sustainability {
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 50%;
    border: none;
    background: transparent;
    color: #29b5c4;

}


.sustainability-container .sustainability i {
    width: 20px;
    height: 20px;
    color: #fff;
    background: var(--primary);
    font-size: 30px;
    border-radius: 50%;
    line-height: 46px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -10px 0 0 -10px;
}

.sustainability-container .sustain-icon {
    /* font-size: 10px; */
    font-family: 'Roboto';
    /* line-height: 13px;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0px; */
    color: #3576A7;
    text-align: center;
    width: 100%;
    /* opacity: 1;
    margin-right: 8px; */
}


.sustainability-container .sustain-count {
    font-size: 10px;
    font-family: 'Roboto';
    line-height: 13px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}



.sustainability-container .sustainability:focus {
    outline: 0;
}



.sustain-tooltip {
    padding: 12px;
  }
  

.sustain-tooltip ul {
    list-style: none;
}



.sustain-tooltip ul li::before {
    content: "\2022";
    color: #3576A7;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -2em;
  }

  .sustain-tooltip .tooltip-sustain-title {
    text-align: left;
    letter-spacing: 0px;
    font-size: 11px;
    line-height: 18px;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    color: #3576A7;
    margin-bottom: 4px;
  }

  .sustain-tooltip .tooltip-sustain-sub-title {
    text-align: left;
    letter-spacing: 0px;
    font-size: 11px;
    line-height: 14px;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: normal;
    margin-top: 12px;
  }
  
  .sustain-tooltip .tooltip-sustain-value {
    margin-top: 8px;
    text-align: left;
    letter-spacing: 0px;
    font-size: 11px;
    line-height: 14px;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: bold;
    color: #3576A7;
  }