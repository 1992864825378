.login-main {
    min-height: calc(100vh - 80px);
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}

.login .title {
    font-size: 64px;
    line-height: 60px;
    font-family: "Roboto";
    align-self: center;
    text-align: center;
    margin: 48px 0;
    font-weight: 700;
    color: #3576a7;
    letter-spacing: -1.6px;

}

.login .sub-title {
    color: rgba(112, 112, 112, 1);
    line-height: 26px;
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 400;
    font-style: italic;
    align-self: center;
    text-align: center;
    width: 62%;
    margin: 64px auto;
}

.login .form {
    padding: 60px 90px;
    max-width: var(--max-width);
    width: 651px;
    min-height: 406px;
    height: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 25px 25px #00000014;
    border-radius: 12px;
    opacity: 1;
    backdrop-filter: blur(21px);
    -webkit-backdrop-filter: blur(21px);
}

.login .form .MuiFormHelperText-root {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-style: italic;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #E31010;
    margin: 0px;
    padding: 8px 8px;
}

.login .form .error-message {
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0px;
    color: #E31010;
    opacity: 1;
    margin-bottom: 12px;
    margin-left: 14px;
    margin-right: 24px;
}

.login .form h3 {
    text-align: left;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0px;
    color: #3576A7;
    opacity: 1;
    width: 469px;
}

.login .form .login-username {
    margin-top: 0px;
    width: 469px;
    background: #EDF7FA 0% 0% no-repeat padding-box;
    border-radius: 2px;
    color: #212529;
    font-size: 18px;
    line-height: 26px;
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
}




.login .form .login-username .MuiInputBase-root {
    font-size: 18px;
    line-height: 26px;
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
}
.login .form .MuiOutlinedInput-input {
    font-size: inherit;
    padding: 9.5px 0px;
}


.login .form .login-password {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 469px;
    background: #EDF7FA 0% 0% no-repeat padding-box;
    border-radius: 2px;
    color: #212529;
    font-size: 18px;
    line-height: 26px;
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
}

.login .form .login-password .MuiInputBase-root {
    font-size: 20px;
    letter-spacing: 3.5px;
}

.login .form .login-password input::placeholder {
    font-size: 18px;
    letter-spacing: 0.0px;
}

.login .form .login-username fieldset,
.login .form .login-password fieldset {
    border-color: transparent;
}

.login a {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    margin-top: 20px;
    text-align: center;
    letter-spacing: 0px;
    color: #3576A7;
    opacity: 1;
}

.login .form .login-button {
    width: 469px;
    height: 60px;
    margin-top: 20px;
    background: #4BBDCC 0% 0% no-repeat padding-box;
    letter-spacing: 2px;
    color: #EDF7FA;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    font-style: normal;
    border-radius: 30px;
    letter-spacing: 2px;
}



.login-main .terms-of-service {
    display: block;
    padding: 64px;
    max-width: var(--max-width);
    width: 709px;
    text-align: center;
    text-align: center;
    letter-spacing: 0px;
    color: #212529;
    font-size: 14px;
    line-height: 26px;
    font-weight: normal;
    font-style: normal;
}

.login-main .terms-of-service a {
    color: #3576A7;
    text-decoration: none;
    font-size: 14px;
}


@media screen and (max-width: 480px) {
    .login .form {
        padding: 30px 30px;
        width: 90%;
        min-height: 406px;
        height: 100%;
        max-height: 100%;
    }

    .login .form .login-username,
    .login .form .login-password,
    .login .form .login-button,
    .login .form h3 {
        width: 90%;
        min-width: 330px;
    }

    .login-main .terms-of-service {
        width: 100%;
        padding: 30px;
    }

    


  }