.table-conatainer {
    margin-bottom: 8px;
    max-height: 100%;
}

.my-submissions-table {
    background-color: #ffffff;
    border-collapse: separate;
    border-spacing: 0 6px;
}

.my-submissions-table thead>tr>th {
    color: rgba(53, 118, 167, 1);
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    font-size: 12px;
    border-bottom: 0px;
    padding: 4px 8px;
    padding-left: 20px;
    line-height: 1.2rem;
    text-align: right;
}

.my-submissions-table thead>tr>th:first-child {
    text-align: center;
}


.my-submissions-table thead>tr>th:last-child {
    padding-right: 35px;
}

.my-submissions-table thead>tr>th:nth-child(3) {
    text-align: center;
}

.my-submissions-table thead>tr>th.flops {
    text-align: center;
}

.my-submissions-table thead>tr>th:nth-child(2) {
    /* padding-left: 20px; */
    text-align: left;
}

.my-submissions-table thead>tr>th:nth-child(7),
.my-submissions-table thead>tr>th:nth-child(8) {
    /* padding-left: 20px; */
    text-align: center;
}

.my-submissions-table .empty-td {
    border: 0px;
}


.my-submissions-table td {
    padding: 8px 8px;
}

.my-submissions-table td:first-child {
    position: relative;
    padding-left: 7px;
    text-align: center;
    border-left-width: 1px;
    border-radius: 5px 0 0 5px;
}

.my-submissions-table td:first-child .money-bar {
    position: absolute;
    background-color: #00d17a;
    border-radius: 5px 0 0 5px;
    width: 5px;
    height: 58px;
    top: -1px;
    left: -1px;

}

.my-submissions-table td:last-child {
    border-right-width: 1px;
    border-radius: 0 5px 5px 0;
    padding-right: 35px;
}


.my-submissions-table tbody>tr>td {
    color: var(--textPrimary);
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
    font-family: 'Roboto', Arial;
    border-width: 1px 0;
    border-radius: 1px;
    border-style: solid;
    border-color: rgba(230, 230, 230, 1);
    border-bottom-width: 1px;
    padding-left: 20px;
    text-align: right;
}

.my-submissions-table tbody>tr>td:nth-child(2) {
    height: 58px;
    /* border-left-width: 1px; */
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    /* border-right-width: 1px; */
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    padding: 0px;
}

.my-submissions-table tbody>tr>td:nth-child(3) {
    text-align: left;
}

.my-submissions-table tbody>tr>td:nth-child(2) .img-container {
    height: 58px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.my-submissions-table tbody>tr>td:nth-child(2) .img-container img {
    width: 28px;
    object-fit: contain;
}

.my-submissions-table .sort-label {
    color: rgba(53, 118, 167, 1);
}

.my-submissions-table .sort-icon {
    color: rgba(53, 118, 167, 1);
    opacity: 0.7;
}

.my-submissions-table .sort-icon-active {
    color: rgba(53, 118, 167, 1);
    opacity: 1;
    font-weight: bold;
}

.my-submissions-table .sort-icon-active>svg>path {
    color: rgba(53, 118, 167, 1);
}

.my-submissions-table .icon-button {
    padding: 4px;
    color: rgba(53, 118, 167, 1);

}


.my-submissions-table .icon {
    width: 20px;
    height: 20px;
}

.my-submissions-table .avatar-small {
    width: 24px;
    height: 24px;
    color: rgba(112, 112, 112, 1);
    font-size: 8px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    background-color: rgba(247, 247, 247, 1);
    border: 1px solid rgba(247, 247, 247, 1);

}

.my-submissions-table .avatar-small img {
    /* filter: blur(3px); */
    /* -webkit-filter: blur(3px); */
    image-rendering: pixelated;
}

.my-submissions-table .tflops-container {
    background-color: #C7D2D5;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    height: 24px;
    position: relative;
}

.my-submissions-table .tflops-container .tflops-progress {
    background-color: #4BBDCC;
    border: 1px solid #4BBDCC;
    border-radius: 4px 0px 0px 4px;
    height: 22px;
    max-width: 100%;
    text-align: center;
    z-index: 1;
    position: absolute;
}

.my-submissions-table .tflops-container .tflops-progress-text {
    text-align: center;
    position: absolute;
    display: flex;
    z-index: 2;
    width: 100%;
    height: 24px;
    color: #F7F7F7;
    font-size: 10px;
    line-height: 11px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}


/* CSS for Row hover */

.my-submissions-table tbody>.MuiTableRow-hover:hover {
    background-color: transparent;
}

.my-submissions-table tbody>tr.MuiTableRow-hover:hover>td {
    /* font-weight: 800; */
}

.my-submissions-table tbody>tr.MuiTableRow-hover:hover>td .icon-button {
    /* color: white; */
}


/* CSS for Row selected */
.my-submissions-table tbody>.Mui-selected {
    background-color: rgba(53, 118, 167, 1);
}

.my-submissions-table tbody>.Mui-selected:hover {
    background-color: rgba(53, 118, 167, 1);
}

.my-submissions-table tbody>tr.Mui-selected>td {
    font-weight: 800;
}

.my-submissions-table tbody>tr.Mui-selected>td .rating-stars {
    color: white;
}

.my-submissions-table tbody>tr.Mui-selected>td .icon-button {
    color: white;
}


.competition-my-submissions__legends {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px 4px;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

.competition-my-submissions__legends .description {
    font-size: 10px;
    line-height: 11px;
    font-weight: 500;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
}


.competition-my-submissions__legend--in-the-money {
    color: #00d27a;
}

.competition-my-submissions__legend--in-the-money:before {
    background: #00d27a;
    border-radius: 1px;
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: 6px;
    vertical-align: -1px;
}

.competition-my-submissions__legend--gold {
    color: #b88121;
}

.competition-my-submissions__legend--gold:before {
    background: #b88121;
    border-radius: 1px;
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: 6px;
    vertical-align: -1px;
}

.competition-my-submissions__legend--silver {
    color: #838280;
}

.competition-my-submissions__legend--silver:before {
    background: #838280;
    border-radius: 1px;
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: 6px;
    vertical-align: -1px;
}

.competition-my-submissions__legend--bronze {
    color: #8e5b3d;
}

.competition-my-submissions__legend--bronze:before {
    background: #8e5b3d;
    border-radius: 1px;
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: 6px;
    vertical-align: -1px;
}

/* .my-submissions-table tbody > tr:nth-child(n+1):nth-child(-n+3) {
    background-color:rgba(0,210,122,.04);
} */
/* .my-submissions-table tbody > tr:nth-child(n+1):nth-child(-n+3) > td:nth-child(1){
    border-left-color: #00d17a;
    border-left-width: 8px;
} */


/* .my-submissions-table tbody > tr:nth-child(n+4):nth-child(-n+7) {
    background-color:rgba(184,129,33,.04)
} */
/* .my-submissions-table tbody > tr:nth-child(n+4):nth-child(-n+7) > td:nth-child(1){
    border-left-color: #b88121;
    border-left-width: 8px;
} */


/* .my-submissions-table tbody > tr:nth-child(n+8):nth-child(-n+10) {
    background-color:rgba(131,130,128,.04)
} */
/* .my-submissions-table tbody > tr:nth-child(n+8):nth-child(-n+10) > td:nth-child(1){
    border-left-color: #838280;
    border-left-width: 8px;
} */


/* .my-submissions-table tbody > tr:nth-child(n+11):nth-child(-n+100) {
    background-color:rgba(142,91,61,.03)
} */
/* .my-submissions-table tbody > tr:nth-child(n+11):nth-child(-n+100) > td:nth-child(1){
    border-left-color: #8e5b3d;
    border-left-width: 8px;
} */



.my-submissions-table .experiment-view:last-child .single-row::after {
    content: ' ';
    position: absolute;
    top: 121px !important;
    left: -10px;
    width: 3px;
    height: calc(100% - 114px);
    background-color: white;
}

.my-submissions-table .competition-experiments-crown {
    display: none;
}



.my-submission-cycle-btn .custom-checkbox-unchecked {
    color: #e6e6e6;
    padding: 4px;
    margin-right: 4px;
}

.my-submission-cycle-btn .custom-checkbox-unchecked.flash-while-disabled {
    background-color: rgba(255, 0, 0, 0.3);
}


.my-submission-cycle-btn .custom-checkbox-checked {
    color: #3576a7;
    padding: 4px;
}

.my-submission-cycle-btn .custom-checkbox-checked {
    color: #3576a7;
    padding: 4px;
}

.my-submission-cycle-btn .custom-checked-icon {
    background-color: #137cbd;
    display: block;
    width: 11px;
    height: 11px;
}

.my-submission-cycle-btn .custom-unchecked-icon {
    background-color: transparent;
    display: block;
    width: 11px;
    height: 11px;
}