.custom-checkbox {
    color: #3576a7;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Roboto', 'Arial';
    font-style: italic;
    width: calc(30%);
}

.custom-checkbox-unchecked {
    color: #e6e6e6;
    padding: 4px;
    margin-right: 4px;
}

.custom-checkbox-unchecked.flash-while-disabled {
    background-color: aliceblue;
}


.custom-checkbox-checked {
    color: #3576a7;
    padding: 4px;
}

.custom-checked-icon {
    background-color: #137cbd;
    display: block;
    width: 11px;
    height: 11px;
}

.custom-unchecked-icon {
    background-color: transparent;
    display: block;
    width: 11px;
    height: 11px;
}