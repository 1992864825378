.archive-experiment-view .single-row {
    position: relative;
    padding-bottom: 40px;
    padding-top: 8px;
}
.archive-experiment-view:last-child .single-row::after {
    content: ' ';
    position: absolute;
    top: 155px;
    left: -10px;
    width: 3px;
    height: calc(100% - 150px);
    background-color: white;
}

.archive-experiment-view .graph-header .heading {
    font-size: 12px;
    font-weight: 700;
    font-style: italic;
    color: var(--primary);
    font-family: 'Roboto', Arial;
    
}


.archive-experiment-view .graph-box .graph-header .icon {
    width: 11px;
    height: 11px;
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: inherit;
    
}

.archive-experiment-view .graph-header button {
    background-color: white;
    padding: 4px 4px;
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: var(--primary);
    min-width: 12px;
    border-radius: 3px;
    border: 1px solid rgba(230, 230, 230, 1);
}



.archive-experiment-view .graph-header .values {
    color: rgba(112, 112, 112, 1);
    font-weight: 400;
    font-style: italic;
    font-size: 12.00px;
    font-family: 'Roboto', Arial;
    letter-spacing: 0;
    text-decoration: none;
}

.archive-experiment-view .graph-header .values::before {
    display: inline-block;
    content: "";
    width: 26px;
    transform: translateY(-2px);
    margin-right: 10px;
}

.archive-experiment-view .graph-header .training::before {
    border-top: 1px solid #ffae15;
}

.archive-experiment-view .graph-header .validation::before {
    border-top: 1px solid #3576a7;
}


.archive-experiment-view .graph-header .values span {
    color: rgba(53, 118, 167, 1);
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    letter-spacing: 0;
    text-decoration: none;
    font-family: 'Roboto', Arial;
}


.archive-experiment-view .single-row .icon {
    width: 16px;
    height: 16px;
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: inherit;
}

.archive-experiment-view .top-buttons {
    position: relative;
    top: 12px;
    padding: 0px 20px 0px 40px;
}



.archive-experiment-view .top-buttons  .status-icon {
    color: var(--blue-status);
    font-size: 20px;
    margin-right: 6px;

}

.archive-experiment-view .top-buttons .stopped {
    color: var(--red-status);
}


.archive-experiment-view .top-buttons button {
    background-color: white;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
    height: 32px;
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: var(--primary);
    min-width: 40px;
}

.archive-experiment-view .top-buttons button:nth-child(1) {
    color: #3576a766;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    font-style: italic;
    text-align: left;
    text-transform: none;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    cursor: default;
}


.archive-experiment-view .top-buttons .like-button {
    margin-right: 6px;
    color: #3576a766;
    padding-right: 2px;
    font-weight: 400;
}

.archive-experiment-view .top-buttons .like-button .claps-count {
    font-size: 8px;
    font-family: 'Roboto';
    line-height: 9px;
    font-weight: normal;
    font-style: italic;
    letter-spacing: 0px;
    color: #70707066;
    opacity: 1;
}
.archive-experiment-view .top-buttons .like-button .like-icon {
    font-size: 16px;
    margin-right: 6px;
    color: #3576a766;
    padding-right: 2px;
    /* border-right: 1px solid rgba(230, 230, 230, 1); */
}


.archive-experiment-view .heading-model {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    font-style: italic;
    color: var(--primary);
    font-family: 'Roboto', Arial;
    margin-right: auto;
    text-align: left;
    opacity: 0.6;
}

.archive-experiment-view .heading-model span {
    text-align: left;
    letter-spacing: 0px;
    color: rgba(112, 112, 112, 1);
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
}

.archive-experiment-view .heading {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    color: var(--primaryText);
    font-family: 'Roboto', Arial;
    margin-right: auto;
    opacity: 0.6;
}

.archive-experiment-view .normal-text {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    color: rgba(112, 112, 112, 1);
    font-family: 'Roboto', Arial;
    letter-spacing: 0px;
    vertical-align: bottom;
}

.archive-experiment-view .inner-view {
    border: 1px solid #e2e2e2;
    padding: 26px 8px 8px 30px;
    margin-left: 20px;
}

.archive-experiment-view .inner-view .avatar-container {
    text-align:center; 
    position: relative;
    width: 50px;
    margin-left: -60px;
    margin-top: 20px;
}


.archive-experiment-view .inner-view .avatar {
    width: 44px;
    height: 44px;
    display: inline-flex;
    z-index: 1;
    margin-left: 6px;
    margin-top: -6px;
    border: 1px solid #3576A7;
    padding: 1px;
    cursor: pointer;
    background-color: white;
}



.archive-experiment-view .inner-view .avatar img {
    border-radius: 50%;
    opacity: 0.6;
}

.archive-experiment-view .inner-view .avatar-container::after { 
    content:'';
    position: absolute;
    left: -1px;
    right: 0;
    top: 15px;
    height: 1px;
    width: 88%;
    background: #e2e2e2;
    z-index:0;
}

.archive-experiment-view:last-child .inner-view .avatar-container::after {
    left: 0px;
}


.archive-experiment-view .inner-view .details-container {
    max-width: 100%; 
    /* max-width: calc(100% - 50px); */
    flex-basis: 100%;
    padding-left: 20px;
    padding-bottom: 0px;
    opacity: 0.6;
}

.archive-experiment-view .inner-view .details-container .objective-box {
    height: 100%;
}

.archive-experiment-view .inner-view .details-container .objective-box .MuiInputBase-root {
    height: 100%;
    margin-bottom: 20px;
}

.archive-experiment-view .inner-view .details-container .objective-box .MuiInputBase-root textarea {
    height: inherit;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Roboto', Arial;
    font-style: italic;
    color: #707070;
    line-height: 14px;
}

.archive-experiment-view .inner-view .details-container .objective-box .MuiInputBase-root textarea:not(.Mui-disabled) {
    background-color: #f7f7f7;
    padding: 5px;
}
.archive-experiment-view .inner-view .details-container .fullscreen-button {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2001;
}

.archive-experiment-view .inner-view .details-container .graph-box {
    display: flex;
    flex-direction: column;
    padding: 8px;
    position: relative;
}

.archive-experiment-view .inner-view .details-container .fullscreen-graph {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 100%;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 2000;
    padding: 24px;
}


.archive-experiment-view .inner-view .details-container .control-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin: 24px 0px 0px 0px;
}

.archive-experiment-view .inner-view .details-container .control-box .color-container {
    background: #f7f7f799 0% 0% no-repeat padding-box;
    height: 42px;
    align-self: center;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    margin-right: 6px;
    padding-left: 4px;
    padding-right: 4px;
}

.archive-experiment-view .inner-view .details-container .control-box .cycle-no {
    color: var(--primary);
    font-weight: normal;
    font-style: italic;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Roboto', Arial;
    letter-spacing: 0;
    text-decoration: none;
}

.archive-experiment-view .inner-view .details-container .control-box .total-cycles {
    text-align: center;
    letter-spacing: 0.00px;
    color: rgba(53, 118, 167, 1);
    -webkit-text-stroke: unset;
    font-size: 12.00px;
    line-height: 14px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
}


.archive-experiment-view .inner-view .details-container .control-box .rounds {
    background: #E6E6E6 0% 0% no-repeat padding-box;
    padding: 8px;
    border-radius: 2px;
    margin: 0 8px 0 8px;
}

.archive-experiment-view .inner-view .details-container .control-box .est-time-title {
    font-size: 10px;
    font-weight: normal;
    font-style: italic;
    color: rgba(112, 112, 112, 1);
    font-family: 'Roboto', Arial;
}
.archive-experiment-view .inner-view .details-container .control-box .est-time {
    text-align: left;
    letter-spacing: 0.00px;
    color: rgba(53, 118, 167, 1);
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    margin: 0px 10px;
}

.archive-experiment-view .inner-view .details-container .detailed-list {
    padding: 0 8px;
    flex-wrap: wrap;
    margin-top: 24px;
    justify-content: space-between;
}

.archive-experiment-view .inner-view .details-container .detailed-list .item {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 4px;
}

.archive-experiment-view .inner-view .details-container .detailed-list .item:nth-child(1),
.archive-experiment-view .inner-view .details-container .detailed-list .item:nth-child(5n+5) {
    padding-left: 0px;
    padding-right: 40px;
}
.archive-experiment-view .inner-view .details-container .detailed-list .item:nth-child(4n+4) {
    padding-right: 0px;
    padding-left: 40px;
}

.archive-experiment-view .inner-view .details-container .detailed-list .item:last-child:not(:nth-child(4n+4)) {
    margin-right: auto;
    
}

.archive-experiment-view .inner-view .details-container .detailed-list .item .head {
    width: 60%;
    text-align: left;
    letter-spacing: 0.00px;
    color: rgba(112, 112, 112, 1);
    font-size: 12.00px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;

}

.archive-experiment-view .inner-view .details-container .detailed-list .item .normal-text {
    width: 40%;
    text-align: right;
    letter-spacing: 0.00px;
    color: rgba(53, 118, 167, 1);
    font-size: 12.00px;
    font-weight: 400;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
}

.archive-experiment-view .bottom-buttons {
    position: relative;
    top: 22px;
    padding: 0px 40px 0px 10px;
    z-index: 50;
}

.archive-experiment-view .bottom-buttons .buttons-list:first-child button:nth-child(3) .icon {
    opacity: 0.6;
}


.archive-experiment-view .bottom-buttons .buttons-list:last-child button:first-child .icon{
    border-left: 1px solid rgba(230, 230, 230, 1);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    opacity: 0.6;
}



.archive-experiment-view .bottom-buttons .buttons-list button:first-child {
    border-left: 1px solid rgba(230, 230, 230, 1);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.archive-experiment-view .bottom-buttons .buttons-list button:last-child {
    border-right: 1px solid rgba(230, 230, 230, 1);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.archive-experiment-view .bottom-buttons .buttons-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.archive-experiment-view .bottom-buttons .buttons-list button {
    background-color: white;
    padding: 8px 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: var(--primary);
    min-width: 28px;
    /* max-width: 28px; */
    height: 28px;
    border-radius: 0px;
    border: 1px solid rgba(230, 230, 230, 1);
    border-left: 0;
}

.archive-experiment-view .bottom-buttons .buttons-list button .title {
    margin-left: 8px;
}



.archive-experiment-view .bottom-buttons .buttons-list .selected {
    background-color: var(--primary);
    color: white;
    border-color: var(--primary);
}

.archive-experiment-view .bottom-buttons .buttons-list .selected .icon {
    color: #E6E6E6;
}

.archive-experiment-view .bottom-buttons .buttons-list .selected img {
    filter: saturate(0%) brightness(1000%) contrast(100%);
}

.archive-experiment-view .bottom-buttons .buttons-list .icon {
    width: auto;
    height: 14px;
}










.archive-experiment-view .inner-view .details-container .control-box .buttons-list {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.archive-experiment-view .inner-view .details-container .control-box .buttons-list button {
    background-color: white;
    padding: 8px 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    color: var(--primary);
    min-width: 28px;
    /* max-width: 28px; */
    height: 28px;
    border-radius: 0px;
    border: 1px solid rgba(230, 230, 230, 1);
    border-left: 0;
}

.archive-experiment-view .inner-view .details-container .control-box .buttons-list button .title {
    margin-left: 8px;
}



.archive-experiment-view .inner-view .details-container .control-box .buttons-list .selected {
    background-color: var(--primary);
    color: white;
    border-color: var(--primary);
}

.archive-experiment-view .inner-view .details-container .control-box .buttons-list .selected .icon {
    color: #E6E6E6;
}

.archive-experiment-view .inner-view .details-container .control-box .buttons-list .selected img {
    filter: saturate(0%) brightness(1000%) contrast(100%);
}

.archive-experiment-view .inner-view .details-container .control-box .buttons-list .icon {
    width: auto;
    height: 14px;
}


.archive-experiment-view .inner-view .details-container .control-box .buttons-list button.first-child {
    border-left: 1px solid rgba(230, 230, 230, 1);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.archive-experiment-view .inner-view .details-container .control-box .buttons-list button.last-child {
    border-right: 1px solid rgba(230, 230, 230, 1);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.archive-experiment-view .inner-view .details-container .control-box .buttons-list {
    background-color: transparent;
}

.archive-experiment-view .inner-view .details-container .control-box .buttons-list .icon {
    width: 20px;
    height: 20px;
}

.archive-experiment-view .inner-view .details-container .control-box .buttons-list button {
    background-color: transparent;
    font-size: 12px;
    padding: 4px 4px;
}


.archive-experiment-view .inner-view .details-container .control-box .legend {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    color: rgba(112, 112, 112, 1);
    font-family: 'Roboto', Arial;
    text-align: center;
    margin: 0 8px;
}

.archive-experiment-view .inner-view .details-container .control-box .legend::before {
    display: inline-block;
    content: "";
    width: 26px;
    transform: translateY(-2px);
    margin-right: 10px;
}
.archive-experiment-view .inner-view .details-container .control-box .training::before {
    border-top: 1px solid #ffae15;
}

.archive-experiment-view .inner-view .details-container .control-box .validation::before {
    border-top: 1px solid #3576a7;
}

.archive-experiment-view .inner-view .details-container .graph-box .epoch {
 align-self: flex-end;
 margin-right: 20px;
 font-size: 10px;
}

.archive-experiment-view .inner-view .details-container .graph-box  .trainingAccuracy,
.archive-experiment-view .inner-view .details-container .graph-box  .trainingLoss {
    color: #ffae15;
}

.archive-experiment-view .inner-view .details-container .graph-box  .validationAccuracy,
.archive-experiment-view .inner-view .details-container .graph-box  .validationLoss {
    color: #3576a7;
}

.archive-experiment-view .single-row .bottom-sheets {
    border: 1px solid #e2e2e2;
    border-top: 0;
    margin-left: 20px;
    padding: 40px;
}