
.notebook-viewer  {
    width: inherit;
    max-width: calc(100vw - 230px);
}


.notebook-viewer .block .cell-row .cell-header {
    background: transparent;
}


.cell-content.output-display:has(table) { 
    justify-content: left !important;
}


.notebook-viewer .block-hidden {
    border-bottom: 1px solid #3576a7;
}


.notebook-viewer .dataframe {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .notebook-viewer .dataframe td, .dataframe th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .notebook-viewer .dataframe tr:nth-child(even){background-color: #f2f2f2;}
  
  .notebook-viewer .dataframe tr:hover {background-color: #ddd;}
  
  .notebook-viewer .dataframe th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #3576a7;
    color: white;
  }