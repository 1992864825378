
.competition-Overview {
    max-width: inherit;
}

.competition-Overview h3 {
    font-family: 'Roboto';
    font-size: 20px;
    line-height: 22px;
    color: var(--primary);
    font-style: italic;
}

.competition-Overview h2 {
    font-family: 'Roboto';
    color: var(--primary);
    font-style: italic;
    font-size: 24px;
    margin: 0px;
    margin-bottom: 32px;
    margin-top: 32px;
}

.competition-Overview h2:first-child {
    margin-top: 0px;
    margin-bottom: 32px;
}



.competition-Overview .details {
    font-family: 'Roboto';
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 1.5;
    font-style: italic;

}


.competition-Overview .details p {
    margin: 0;

}


.competition-Overview .resources {
    list-style: none;
    margin-bottom: 12px !important;
}

.competition-Overview .resources li {
    margin-top: 3px;
    margin-bottom: 3px;
}
.competition-Overview .acknowledgements {
    margin-left: auto;
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    column-gap: 20px;
    list-style: none;
}

.competition-Overview .acknowledgements li {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.competition-Overview .publication-tokens {
    margin-left: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.competition-Overview .publication-tokens li {
    margin-top: 3px;
    margin-bottom: 3px;
    font-family: 'Roboto';
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 14px;
}


.competition-Overview .details {
    font-family: 'Roboto';
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
    font-style: italic;
    font-weight: normal;
    text-align: left;
    letter-spacing: 0px;
    color: #333;
    height: 80vh;
    overflow-y: auto;
}

.competition-Overview .details ul {
    list-style: none;
    margin-bottom: 30px;
}
.competition-Overview .details ul li {
    margin-top: 3px;
    margin-bottom: 3px;
}

.competition-Overview .details ul li span {
    color: var(--primary);
    font-weight: 500;
    font-family: 'Roboto';
}


.competition-Overview .details .leader-board-title {
    color: var(--primary);
    font-weight: bold;
    font-style: italic;
    font-family: 'Roboto';
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0px;
    margin-bottom: 40px;
}

.competition-Overview .details .prize-list {
    margin-bottom: 16px;

}

.competition-Overview .details .prize-list .prize-title {
    text-align: left;
    color: var(--primary);
    font-weight: normal;
    font-style: italic;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0px;
    margin-bottom: 6px;
}

.competition-Overview .details .prize-list .prize-value {
    text-align: left;
    color: #707070;
    font-weight: normal;
    font-style: italic;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
}


.html-section {
    margin-top: 20px !important;
}

.html-section ul {
    /* margin: unset !important; */
    list-style: auto !important;
    list-style-type: disc !important;
}

/* .html-section ul li {
    margin: unset !important;
} */

.html-section span {
    font-weight: normal !important;
    color: #333333 !important;
}

.html-section pre {
    
    background: #000;
    border: 1px solid #ddd;
    border-left: 3px solid var(--primary);
    color: #fff;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}


.html-section p:has(img),
.html-section div:has(img) {
  text-align: center;
}



.competition-Overview .left-bar {
    display: flex;
    overflow: hidden;
    min-height: 48px;
    flex-direction: column;
    min-width: 90px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.competition-Overview .left-bar .inner {
    font-family: Segoe UI, Tahoma, sans-serif;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--primary)
}


.competition-Overview .right-bar {
    padding-left: 16px;
    width: 100%;

}