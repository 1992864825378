.codeview {
    max-height: 0;
    transition: 0.5s;
    overflow: hidden;
}

.codeview.expand {
    max-height: 500px;
    background: #1E1E1E 0% 0% no-repeat padding-box;
    border-radius: 5px;
    overflow-y: auto;
    padding: 16px;
}

.codeview .code-editor {
    font-size: 12px;
    padding: 0;
}


.codeview button .copy-icon {
    filter: brightness(500%);
    height: 18px;
}

.codeview button .copy-icon:hover {
    filter: brightness(100%);
}