.empty-graph {

    position: absolute;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
}

.empty-graph .graph-message {
    position: absolute;
    top: 40%;
    width: 50%;
    left: 25%;
    letter-spacing: 0px;
    color: var(--primary);
    text-align: center;
    font: italic normal normal 12px/14px Roboto;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    font-weight: normal;
}