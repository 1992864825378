.sidebar .sidebar-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    flex-wrap: nowrap;
}

.sidebar .sidebar-links .logo {
    margin-top: 50px;
    min-height: 50px;
    max-height: 50px;
    align-self: center;
    z-index: 1000;
}

.sidebar .sidebar-links .logo > img {
    height: 47px;
    transition: all 0.5s ease-out;
}

.sidebar .sidebar-links .full-logo > img {
    height: 36px;
}

.sidebar .sidebar-links .menu-items {
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.sidebar .sidebar-links .toggle-button {
    align-self: flex-start;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 38px;

}

.sidebar .sidebar-links .toggle-button > button {
    position: relative;
    right: -7px;
    box-sizing: content-box;
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
    cursor: pointer;
    background-color: white;
    color: rgba(53, 118, 167, 1);
    -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
    font-size: 27px;
    font-weight: 500;
    font-style: normal;
    font-family: 'Roboto', Arial;
    width: 8px;
    height: 8px;
    line-height: 21px;
    border-radius: 5px;
}
.sidebar .sidebar-links .toggle-button > button:hover {
    background-color: #f9f9f9;
}
.sidebar .sidebar-links .toggle-button > button .MuiToggleButton-label {
    margin-bottom: 3px;
}

.sidebar .sidebar-links .toggle-button > .toggle-open {
    -ms-transform: rotate(180.00deg);
    -webkit-transform: rotate(180.00deg);
    transform: rotate(180.00deg);
}
.sidebar .sidebar-links .toggle-button > .toggle-close {
    -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
}


.sidebar .sidebar-links .line {
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    position: relative;
    bottom: -17px;
}


.sidebar .sidebar-links .button {
    width: 100%;
    height: 46px;
    min-height: 46px;
    border-radius: 1px;
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    text-decoration: none;
}

.sidebar .sidebar-links .button .img-container {
    min-width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.sidebar .sidebar-links .button img {
    height: 20px;
    margin-right: 16px;
}

.sidebar .sidebar-links .button:hover img,
.sidebar .sidebar-links .button-selected img {
    -webkit-filter: saturate(0%) brightness(1000%) contrast(100%); /* Safari 6.0 - 9.0 */
    filter: saturate(0%) brightness(1000%) contrast(100%);
}

.sidebar .sidebar-links .button:hover {
    background-color: #3576a7;
    color: #fff;
    background-image: url('../../assets/icon-hover.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



.sidebar .sidebar-links .button-selected {
    background-color: #3576a7;
    color: #fff;
    background-image: url('../../assets/icon-hover.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.sidebar .sidebar-links .button-selected {
    color: #fff;
}

.sidebar .sidebar-links .icon {
    width: 30px;
    color: inherit;
    margin-right: 16px;
}

.sidebar .sidebar-links .button .label {
    font-weight: 500;
    font-size: 12px;
    font-style: normal;
    font-family: 'Roboto', Arial;
    white-space: nowrap;
    height: 14px;
}

.sidebar .sidebar-links .avatar {
    width: 32px;
    height: 32px;
    margin: auto;
    position: fixed;
    border: 1px solid #3576A7;
    padding: 1px;
    bottom: 20px;

}
.sidebar .sidebar-links .avatar img {
    border-radius: 50%;
}

.products-list > .product:not(.selected):hover {
    background-color: #eeeeee;
  }
  