.table-container {
  margin-bottom: 8px;
  margin-top: 16px;
}

.table {
  background-color: #ffffff;
  border-collapse: separate;
  border-spacing: 0 5px;
  border: 0;
}

.table thead > tr > th {
  color: rgba(53, 118, 167, 1);
  font-weight: 400;
  font-style: italic;
  font-family: 'Roboto', Arial;
  font-size: 12px;
  border-bottom: 0px;
  padding: 2px 8px;
  line-height: 1rem;
}

.table td {
  padding: 2px 8px;
}

.table td:first-child {
  border-left-width: 0px;
  border-radius: 5px 0 0 5px;
}

.table td:last-child {
  border-right-width: 0px;
  border-radius: 0 5px 5px 0;
}

.table-row {
  transition: background-color 0.2s ease;
}

.row-even {
  background-color: #ffffff;
}

.row-odd {
  background-color: #f8fafc;
}

.table-row:hover {
  background-color: #f1f5f9;
} 