.training-plan {
    max-height: 0;
    transition: 0.5s;
    overflow: hidden;
}

.training-plan h3 {
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    font-style: italic;

}

.training-plan.expand {
    max-height: 500px;
    background: white;
    overflow-y: auto;
    
}


.training-plan .detailed-list {
    /* border: 1px solid #f7f7f7;
    border-radius: 5px; */
    flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 48px;
    justify-content: flex-start;
}

.training-plan .detailed-list:last-of-type {
    margin-bottom: 4px;
}

.training-plan .detailed-list .item {
    display: flex;
    margin-bottom: 5px;
}


.training-plan .detailed-list .item .head {
    width: 60%;
    text-align: left;
    letter-spacing: 0px;
    color: rgba(112, 112, 112, 1);
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
}

.training-plan .detailed-list .item .normal-text {
    width: 40%;
    text-align: right;
    letter-spacing: 0px;
    color: rgba(53, 118, 167, 1);
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    font-style: italic;
    font-family: "Roboto", Arial;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 20px;
}