
:root {
  --primary: #3576a7;
  --white: #ffffff;
  --textPrimary: #707070;
  --blue-status: rgba(75, 189, 204, 1);
  --orange-status: rgba(255, 167, 0, 1);
  --red-status: rgba(227, 16, 16, 1);
  --grey-status: #e6e6e6;
  --crack: #410E52;
  --inclusion: #FF9C29;
  --porosity: #7DD0A2;

}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  
}

.m-auto { margin: auto; }
.mx-auto { margin-left: auto; margin-right: auto; }
.my-auto { margin-top: auto; margin-bottom: auto; }

.m-1 { margin: 2px; }
.m-2 { margin: 4px; }
.m-4 { margin: 8px; }

.mt-1 { margin-top: 2px; }
.mt-2 { margin-top: 4px; }
.mt-4 { margin-top: 8px; }
.mt-8 { margin-top: 16px; }

.mb-1 { margin-bottom: 2px; }
.mb-2 { margin-bottom: 4px; }
.mb-4 { margin-bottom: 8px; }
.mb-8 { margin-bottom: 16px; }

.ml-1 { margin-left: 2px; }
.ml-2 { margin-left: 4px; }
.ml-4 { margin-left: 8px; }

.mr-1 { margin-right: 2px; }
.mr-2 { margin-right: 4px; }
.mr-4 { margin-right: 8px; }


.mx-1 { margin-left: 2px; margin-right: 2px;}
.my-1 { margin-top: 2px; margin-bottom: 2px;}

.mx-2 { margin-left: 4px; margin-right: 4px;}
.my-2 { margin-top: 4px; margin-bottom: 4px;}

.mx-4 { margin-left: 8px; margin-right: 8px;}
.my-4 { margin-top: 8px; margin-bottom: 8px;}

.mx-8 { margin-left: 16px; margin-right: 16px;}
.my-8 { margin-top: 16px; margin-bottom: 16px;}





/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 5px;
} */
 
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */