.comments {
    max-height: 0;
    transition: 0.5s;
    overflow: hidden;
}

.comments.expand {
    max-height: 400px;
    background: white;
    overflow-y: auto;
}

.comments .no-comments {
    color: var(--textPrimary);
}

.comments .comments-count {
    width: 94%;
    margin-bottom: 24px;
    color: var(--textPrimary);
    font-weight: bold;
    font-size: 12px;
    font-style: italic;
    line-height: 14px;
}

.comments .comment {
    padding: 10px 0px;
    align-items: flex-start;
}

.comments .avatar {
    width: 30px;
    height: 30px;
    display: flex;
    z-index: 1;
    margin-right: 12px;
    cursor: pointer;
    border: 1px solid #3576A7;
    padding: 2px;
}

.comments .avatar img {
 border-radius: 50%;
}

.comments .comment-container {
    position: relative;
    border: 1px solid #F7F7F7;
    display: flex;
    flex-direction: column;
    padding: 10px 12px 20px;
    background-color: #f7f7f799;
    border-radius: 5px 5px 0px 0px;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    font-weight: normal;

}

.comments  .child-comments {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    background-color: #F7F7F7;
}
/* .comments .comment-container::after {
    content: ' ';
    position: absolute;
    top: 10px;
    left: -6px;
    width: 10px;
    height: 10px;
    background: #fcfcfc;
    border: 1px solid #f0f0f0;
    border-right: 0;
    border-top: 0;
    transform: rotate(45deg);
} */


.comments  .comment-header {
    margin-bottom: 2px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;

}


.comments .comment-header .name {
    cursor: pointer;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0px;
    color: var(--primary);
}

.comments .comment-header .date {
    margin-left: 12px;
    font-family: 'Roboto';
    font-size: 10px;
    line-height: 11px;
    font-style: italic;
    font-weight: normal;
    letter-spacing: 0px;
    color: var(--textPrimary);
}

.comments .comment-header button {
    font-size: 12px;
    color: #707070;
    font-weight: 400;
    text-transform: none;
    padding: 0 5px;
    min-width: 10px;
    font-style: italic;
}

.comments .comment-header .like-button {
    font-size: 14px;
    margin-right: 4px;
    color: var(--primary);
    padding-right: 2px;
    border-right: 1px solid rgba(230, 230, 230, 1);
}

.comments .text {
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    color: rgba(112, 112, 112, 1);
    font-family: 'Roboto', Arial;
    margin-bottom: 8px;
}

.comments .create-comment-btn {
    min-width: 32px;
    width: 32px;
    height: 32px;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    text-transform: inherit;
    border-width: 0px;
    border-radius: 0px;
    font-family: 'Roboto', Arial;
    color: #3576a7;
}

.comments .create-comment-btn.Mui-disabled {
    color: #E6E6E6;
}

.comments .create-reply-btn {
    border-color: #F7F7F7;
}
